import { useRef } from 'react';
import storage from 'local-storage-fallback';
import constants from 'appConstants';
import { modalController } from 'components/reusables/ModalManager/modalController';
import { MODALS } from 'components/reusables/ModalManager/config';
import { TOOLBAR_MODES } from 'components/views/BrokerView/views/RanchEdit/components/Map/utils';
import { hivesAmountKey } from 'utils';

const clearAllListeners = ({ map, polygons }) => {
    if (!map) {
        return;
    }
    window.google.maps.event.clearListeners(map, 'click');
    polygons.forEach(polygon => window.google.maps.event.clearListeners(polygon, 'click'));
};

const addClickListener = (target, event, listener) => {
    window.google.maps.event.clearListeners(target, event);
    window.google.maps.event.addListener(target, event, listener);
};

const getHivesAmount = () => storage.getItem(hivesAmountKey) || constants.DEFAULT_HIVES_AMOUNT;

const getName = ({ name, locations }) => {
    const nameExists = locations.some(location => String(location.name) === String(name));
    if (nameExists) {
        return getName({ name: name + 1, locations });
    }
    return name.toString();
};

const getLocationModel = ({ lat, lng, locations }) => ({
    lat,
    lng,
    name: getName({ name: locations.length + 1, locations }),
    hivesAmount: getHivesAmount(),
    bhomes: [],
    standardHives: [],
    plannedBhomesAmount: 0,
    companyId: null,
    id: `${constants.NEW_LOCATION_PREFIX}${lat}${lng}`,
    note: '',
});

const useMarkerMode = ({ map, polygonsRef, createLocation, removeLocation, updateLocation, form }) => {
    const isDraggingRef = useRef(false);
    const handleAddLocationMarker = ({ latLng }) => {
        const lat = latLng.lat();
        const lng = latLng.lng();
        const locations = form.getValues('locations');
        const location = getLocationModel({ lat, lng, locations });
        const locationExists = locations.some(item => item.id === location.id);
        if (locationExists) {
            return;
        }
        createLocation(location);
        modalController.set({
            name: MODALS.LOCATION_DETAILS,
            props: {
                location,
                orders: form.getValues('orders'),
                removeLocation: () => removeLocation(locations.length),
                updateLocation: item => updateLocation(locations.length, { ...location, ...item }),
            },
        });
    };

    const handleEditLocationMarker =
        ({ location, mode, index, isDraggingRef }) =>
        e => {
            e.preventDefault();
            e.stopPropagation();
            if (mode !== TOOLBAR_MODES.CREATE_LOCATION.title || isDraggingRef.current) {
                return;
            }
            modalController.set({
                name: MODALS.LOCATION_DETAILS,
                props: {
                    location,
                    isEdit: true,
                    isWithDeleteButton: true,
                    orders: form.getValues('orders'),
                    removeLocation: () => removeLocation(index),
                    updateLocation: item => updateLocation(index, { ...location, ...item }),
                },
            });
        };

    const handleMapClick = handler => {
        clearAllListeners({ map, polygons: polygonsRef.current });
        addClickListener(map, 'click', handler);
        polygonsRef.current.forEach(polygon => addClickListener(polygon, 'click', handler));
    };

    const handleMarkerModeChange = mode => {
        switch (mode) {
            case TOOLBAR_MODES.CREATE_LOCATION.title:
                handleMapClick(handleAddLocationMarker);
                break;
            default:
                break;
        }
    };

    return { handleMarkerModeChange, handleEditLocationMarker, isDraggingRef };
};

export default useMarkerMode;
