import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
    fetchCompaniesWithUsers,
    fetchRanch,
    fetchRanchManagers,
    fetchUsers,
    fetchBhomes,
} from 'components/views/BrokerView/actions';
import { getSelectedRanchId } from 'components/views/BrokerView/selectors';
import { getDefaultValuesStep2 } from '../utils';

const useFetchData = form => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const navigate = useNavigate();
    const selectedRanchId = useSelector(getSelectedRanchId);
    useEffect(() => {
        dispatch(fetchUsers({ type: 'broker' }));
        dispatch(fetchRanchManagers());
        dispatch(fetchCompaniesWithUsers());
        dispatch(fetchBhomes());
    }, [dispatch]);

    useEffect(() => {
        if (id && !selectedRanchId) {
            dispatch(
                fetchRanch({
                    id,
                    rejector: () => navigate('/'),
                    resolver: data => {
                        const newValues = getDefaultValuesStep2(data);
                        form.reset(newValues);
                    },
                })
            );
        }
        // This check should be used to load only once when the page is loading
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export default useFetchData;
