export const VARIANTS = {
  TEXT: 'text',
  CONTAINED: 'contained',
  OUTLINED: 'outlined'
};

export const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
};

export const COLORS = {
  DEFAULT: 'default',
  ORANGE: 'orange',
  BLUE: 'blue',
  RED: 'red',
  GRAY: 'gray',
  YELLOW: 'yellow'
};
