import React, { useRef, memo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import Panel from 'components/reusables/Panel';
import RanchForm from './RanchForm';
import RanchDetails from './RanchDetails';
import './LeftPanel.scss';

const Header = () => <h2>New Ranch</h2>;

const useShowHeader = () => {
    const { id } = useParams();
    const isEditRef = useRef(!!id);
    return !!isEditRef.current;
};

const LeftPanel = ({ setIsFirstStep, isFirstStep }) => {
    const isHeaderShown = useShowHeader();
    const Component = isFirstStep ? RanchForm : RanchDetails;
    return (
        <Panel className="left-panel">
            {!isHeaderShown && (
                <Panel.Header>
                    <Header />
                </Panel.Header>
            )}
            <Component setIsFirstStep={setIsFirstStep} />
        </Panel>
    );
};

LeftPanel.propTypes = {
    setIsFirstStep: PropTypes.func,
    isFirstStep: PropTypes.bool,
};

export default memo(LeftPanel);
