import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import { getTotalPerformances } from 'components/views/BrokerView/utils';
import { getBeekeepers, getSelectedBrokerFilter } from 'components/views/BrokerView/selectors';
import PerformanceItem from './PerformanceItem';
import './RanchesPerformanceBlock.scss';

const RanchesPerformanceBlock = ({ ranches }) => {
    const selectedBrokerFilter = useSelector(getSelectedBrokerFilter);
    const allBeekeepers = useSelector(state => getBeekeepers(state, selectedBrokerFilter), arrayOfObjectsShallowEqual);
    const { available, contracted, assigned, planned, placed } = getTotalPerformances({
        ranches,
        allBeekeepers,
    });

    return (
        <div className="ranches-performance-container">
            <PerformanceItem
                label="Hive supply"
                value={available}
                tooltipText={
                    <div className="tooltip-left-align">
                        Total of all hives <br />
                        available for use
                    </div>
                }
            />
            <PerformanceItem label="Contracted" value={contracted} tooltipText="Contracted hives" />
            <PerformanceItem
                label="Assigned / Planned"
                value={`${assigned} / ${planned}`}
                tooltipText={
                    <div className="tooltip-left-align">
                        Assigned hives out <br />
                        of planned hives
                    </div>
                }
            />
            <PerformanceItem label="Placed" value={placed} tooltipText="Placed hives" />
        </div>
    );
};

export default RanchesPerformanceBlock;

RanchesPerformanceBlock.propTypes = {
    ranches: PropTypes.arrayOf(PropTypes.shape()),
};
