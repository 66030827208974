import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from '@beewise/icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './IconButton.scss';

const TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SECONDARY_LIGHT: 'secondary-light'
};

const IconButton = forwardRef(
  (
    { icon, onClick, disabled, isActive, isInverted, className, type = TYPES.PRIMARY, ...props },
    ref
  ) => (
    <button
      className={cn(
        'btn-icon',
        className,
        type,
        { 'btn-active': isActive },
        { 'btn-inverted': isInverted }
      )}
      onClick={onClick}
      disabled={disabled}
      ref={ref}
      {...props}
    >
      {typeof icon === 'string' ? <Icon type={icon} /> : <FontAwesomeIcon icon={icon} />}
    </button>
  )
);

IconButton.propTypes = {
  icon: PropTypes.shape().isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isActive: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
  isInverted: PropTypes.bool
};

export default IconButton;
