import React from 'react';
import Button, { VARIANTS, COLORS } from '@beewise/button-v2';
import Card, { VARIANTS as CARD_VARIANTS } from 'components/reusables/Card';
import {
    faUserCircle,
    faUserTie,
    faPhone,
    faFile,
    faWheat,
    faThumbsUp,
    faArrowsMaximize,
    faPencil,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import './DetailsCard.scss';

const getContacts = items => {
    const firstContact = items?.[0]?.username;
    if (!items?.length || items?.length === 1) {
        return firstContact;
    }
    return `${firstContact} + ${items.length - 1}`;
};

const getValue = value => value ?? '-';

const getCardItems = selectedRanch => [
    {
        value: getValue(selectedRanch?.brokerInfo?.username),
        text: 'Broker',
        icon: faUserCircle,
    },
    {
        value: getValue(selectedRanch?.ranchManager?.username),
        text: 'Ranch manager',
        icon: faUserTie,
    },
    {
        value: getValue(getContacts(selectedRanch?.contacts)),
        text: 'Contacts',
        icon: faPhone,
    },
    {
        value: getValue(selectedRanch?.requiredHivesAmount),
        text: 'Contracted',
        icon: faFile,
    },
    {
        value: getValue(selectedRanch?.crop),
        text: 'Crop',
        icon: faWheat,
    },
    {
        value: getValue(selectedRanch?.quality),
        text: 'Quality',
        icon: faThumbsUp,
    },
    {
        value: getValue(selectedRanch?.totalArea),
        text: 'Contracted acres',
        icon: faArrowsMaximize,
    },
];

const CardItem = ({ text, icon, value }) => (
    <div className="card-item">
        <FontAwesomeIcon icon={icon} />
        {text}: {value}
    </div>
);

const CardColumn = ({ column }) => (
    <div className="details-card-column">
        {column.map(({ value, text, icon }) => (
            <CardItem key={text} text={text} icon={icon} value={value} />
        ))}
    </div>
);

CardColumn.propTypes = {
    column: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

CardItem.propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.shape().isRequired,
    value: PropTypes.string.isRequired,
};

const splitArrayIntoColumns = (array, columns = 2) => {
    const result = Array(columns)
        .fill()
        .map(() => []);
    array.forEach((item, index) => {
        result[index % columns].push(item);
    });
    return result;
};

const DetailsCard = ({ selectedRanch, setIsFirstStep }) => {
    const cardItems = getCardItems(selectedRanch);
    const [leftColumn, rightColumn] = splitArrayIntoColumns(cardItems);

    return (
        <Card className="details-card" variant={CARD_VARIANTS.OUTLINED}>
            <div className="details-card-header">
                <div>
                    <h3>{selectedRanch?.name}</h3>
                    <p className="subheader">
                        {selectedRanch?.companyName} {selectedRanch?.totalArea && `| ${selectedRanch?.totalArea} acres`}
                    </p>
                </div>
                <Button
                    variant={VARIANTS.TEXT}
                    color={COLORS.BLUE}
                    onClick={() => setIsFirstStep(true)}
                    startIcon={faPencil}
                >
                    Edit details
                </Button>
            </div>
            <div className="row details-card-info ">
                <CardColumn column={leftColumn} />
                <CardColumn column={rightColumn} />
            </div>
        </Card>
    );
};

DetailsCard.propTypes = {
    selectedRanch: PropTypes.shape().isRequired,
    setIsFirstStep: PropTypes.func.isRequired,
};

export default DetailsCard;
