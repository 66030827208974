import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@beewise/icon-button';
import Tooltip from '@beewise/tooltip';
import SearchBoxV2 from 'components/reusables/Map/components/SearchBoxV2';
import { TOOLBAR_MODES } from '../utils';
import './Toolbar.scss';

const Toolbar = ({ mode, setMode, map }) => (
    <div className="toolbar">
        {Object.values(TOOLBAR_MODES).map(({ icon, title, tooltip }) => (
            <Tooltip key={title} text={tooltip} position="top center">
                <IconButton icon={icon} onClick={() => setMode(title)} isActive={mode === title} />
            </Tooltip>
        ))}
        <SearchBoxV2 map={map} />
    </div>
);

Toolbar.propTypes = {
    mode: PropTypes.string,
    setMode: PropTypes.func,
    map: PropTypes.shape(),
};

export default Toolbar;
