import React from 'react';
import PropTypes from 'prop-types';
import { FormField } from '@beewise/hook-form';
import EditBeekeeper from './EditBeekeeper';
import './Beekeepers.scss';
import EditableListItem from '../components/EditableListItem';

const ViewItem = ({ remove, value, options, handleEdit }) => {
    const { label: beekeeperName, data } =
        options.flatMap(option => option.options).find(option => option.value === value.beekeeperId) ?? {};
    return (
        <EditableListItem handleEdit={handleEdit} handleRemove={remove}>
            <span className="beekeeper-color" style={{ backgroundColor: data?.color }} />
            {beekeeperName}
            <span className="text-secondary">| {value.hivesAmount} hives</span>
            {value.isInstaller && <span className="beekeeper-badge">Installer</span>}
        </EditableListItem>
    );
};

ViewItem.propTypes = {
    remove: PropTypes.func.isRequired,
    value: PropTypes.shape().isRequired,
    options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    handleEdit: PropTypes.func.isRequired,
};

const BeekeeperItem = ({ control, remove, index, isEdited, options, setEditedIndex }) => {
    const handleRemove = () => remove(index);
    const handleEdit = () => setEditedIndex(index);
    return isEdited ? (
        <EditBeekeeper control={control} remove={handleRemove} index={index} options={options} />
    ) : (
        <FormField
            control={control}
            name={`orders.${index}`}
            remove={handleRemove}
            options={options}
            handleEdit={handleEdit}
            Component={ViewItem}
        />
    );
};

BeekeeperItem.propTypes = {
    control: PropTypes.shape().isRequired,
    remove: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    isEdited: PropTypes.bool.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    setEditedIndex: PropTypes.func.isRequired,
};

export default BeekeeperItem;
