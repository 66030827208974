import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { getSelectedRanch } from 'components/views/BrokerView/selectors';
import { deleteRanchById } from 'components/views/BrokerView/actions';
import { modalController } from 'components/reusables/ModalManager/modalController';
import { MODALS } from 'components/reusables/ModalManager/config';
import Footer from '../Footer';
import DetailsCard from './DetailsCard';
import Beekeepers from './Beekeepers';
import Installers from './Installers';
import useSubmitForm from './useSubmitForm';

const RanchDetails = ({ setIsFirstStep }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const selectedRanch = useSelector(getSelectedRanch, shallowEqual);
    const form = useFormContext();
    const { control, getValues } = form;

    const { onSubmit } = useSubmitForm({ form });

    const onCancel = () => {
        navigate('/');
    };

    const onDelete = () => {
        modalController.set({
            name: MODALS.DELETE_RANCH,
            props: {
                onConfirm: () =>
                    dispatch(
                        deleteRanchById(selectedRanch.id, () => {
                            navigate('/');
                        })
                    ),
                onClose: onCancel,
                ranchName: selectedRanch.name,
            },
        });
    };

    return (
        <form onSubmit={onSubmit}>
            <DetailsCard selectedRanch={selectedRanch} setIsFirstStep={setIsFirstStep} />
            <hr />
            <Beekeepers control={control} getValues={getValues} />
            <hr />
            <Installers control={control} />
            <Footer onCancel={onCancel} onDelete={onDelete} isSelectedRanch={!!selectedRanch} />
        </form>
    );
};

RanchDetails.propTypes = {
    setIsFirstStep: PropTypes.func,
};

export default RanchDetails;
