import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import constants from 'appConstants';
import GoogleMapReact from '@beewise/google-maps-v2';
import './MapV2.scss';

const DEFAULT_CENTER = { lat: 42.6, lng: -37.47 };
const DEFAULT_ZOOM = 4;

const {
    MAP: { API_KEY, DEFAULT_CURSOR_URL },
} = constants;

const MapV2 = forwardRef(({ isMapMovable, children, extraOptions, onGoogleApiLoaded, handleMapChange }, ref) => (
    <section id="map-v2">
        <GoogleMapReact
            ref={ref}
            apiKey={API_KEY}
            defaultCenter={DEFAULT_CENTER}
            defaultZoom={DEFAULT_ZOOM}
            onGoogleApiLoaded={onGoogleApiLoaded}
            onChange={handleMapChange}
            options={{
                draggable: isMapMovable,
                draggableCursor: DEFAULT_CURSOR_URL,
                fullscreenControl: false,
                zoomControl: false,
                minZoom: 2,
                scaleControl: true,
                mapTypeId: 'hybrid',
                className: 'map-v2',
                restriction: {
                    latLngBounds: {
                        north: 85,
                        south: -85,
                        west: -180,
                        east: 180,
                    },
                },
            }}
            containerProps={{
                id: 'map',
            }}
        >
            {children}
        </GoogleMapReact>
        {extraOptions}
    </section>
));

MapV2.propTypes = {
    isMapMovable: PropTypes.bool,
    extraOptions: PropTypes.shape(),
    children: PropTypes.node,
    onGoogleApiLoaded: PropTypes.func,
    handleMapChange: PropTypes.func,
};

export default MapV2;
