import React, { useCallback, useEffect, useRef, useState, memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import constants from 'appConstants';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import OutsideClickHandler from 'components/reusables/OutsideClickHandler';
import './SearchBox.scss';

const latLngValidation = (lat, lng) => {
    const isLatValid = !Number.isNaN(lat) && lat >= -90 && lat <= 90;
    const isLngValid = !Number.isNaN(lng) && lng >= -180 && lng <= 180;
    return isLatValid && isLngValid;
};

const SearchBoxV2 = ({ map }) => {
    const input = useRef(null);
    const searchBox = useRef(null);
    // eslint-disable-next-line no-unused-vars
    const [isLatLng, setIsLatLng] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    const isInputShown = isVisible || input.current?.value;

    const handleOnPlacesChanged = useCallback(() => {
        const places = searchBox.current.getPlaces();
        if (places?.[0]?.geometry) {
            const lat = places[0].geometry.location.lat();
            const lng = places[0].geometry.location.lng();
            map?.panTo({ lat, lng });
            map?.setZoom(constants.MAP.ZOOM_LEVEL);
        }
    }, [map]);

    const handleInputChange = useCallback(() => {
        const [latStr, lngStr] = (input.current?.value || '').split(',');
        const lat = parseFloat(latStr);
        const lng = parseFloat(lngStr);
        const isLatLngValid = latLngValidation(lat, lng);
        setIsLatLng(isLatLngValid);
        if (isLatLngValid) {
            map?.panTo({ lat, lng });
            map?.setZoom(constants.MAP.ZOOM_LEVEL);
        }
    }, [map]);

    useEffect(() => {
        if (!searchBox.current && window.google?.maps?.places && input.current) {
            searchBox.current = new window.google.maps.places.SearchBox(input.current);
            searchBox.current.addListener('places_changed', handleOnPlacesChanged);
        }

        return () => {
            if (!searchBox.current) return;
            window.google?.maps?.event?.clearInstanceListeners(searchBox.current);
            searchBox.current = null;
        };
    }, [handleOnPlacesChanged]);

    useEffect(() => {
        const inputElement = input.current;
        inputElement?.addEventListener('input', handleInputChange);

        return () => inputElement?.removeEventListener('input', handleInputChange);
    }, [handleInputChange]);

    return (
        <OutsideClickHandler onOutsideClick={() => setIsVisible(false)} className="map-search-v2 t1">
            <FontAwesomeIcon icon={faSearch} onClick={() => setIsVisible(true)} />
            <input autoComplete="off" placeholder="Search..." ref={input} className={isInputShown ? 'active' : ''} />
        </OutsideClickHandler>
    );
};

SearchBoxV2.propTypes = {
    map: PropTypes.shape(),
};

export default memo(SearchBoxV2);
