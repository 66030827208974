import {
    faDrawSquare,
    faLocationPlus,
    faMapLocationDot,
    faCircleMinus,
    faRoute,
    faPenLine,
} from '@fortawesome/pro-light-svg-icons';

export const TOOLBAR_MODES = {
    DRAW_POLYGON: {
        icon: faDrawSquare,
        title: 'draw_polygon',
        tooltip: 'Draw polygon',
    },
    EDIT_POLYGON: {
        icon: faPenLine,
        title: 'edit_polygon',
        tooltip: 'Edit polygon',
    },
    DELETE_POLYGON: {
        icon: faCircleMinus,
        title: 'delete_polygon',
        tooltip: 'Delete polygon',
    },
    AUTOPLACE: {
        icon: faMapLocationDot,
        title: 'autoplace',
        tooltip: 'Autoplace',
    },
    CREATE_LOCATION: {
        icon: faLocationPlus,
        title: 'create_location',
        tooltip: 'Place a drop',
    },
    DRAW_ROAD: {
        icon: faRoute,
        title: 'draw_road',
        tooltip: 'Mark access road',
    },
};
