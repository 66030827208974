import React, { useEffect, useState } from 'react';
import { noop } from 'lodash-es';
import { useSelector } from 'react-redux';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import Button from '@beewise/button';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { useWatch } from 'react-hook-form';
import { InputFormField, SelectFormField, VALIDATIONS } from '@beewise/hook-form';
import constants from 'appConstants';
import { modalController } from 'components/reusables/ModalManager/modalController';
import { MODALS } from 'components/reusables/ModalManager/config';
import { ROOT_ERRORS, getRootLevelErrors } from 'components/reusables/Form/Errors';
import { getUnitsDefinitionFromCountry } from 'utils/isIsraeliRanch';
import { getGrowers } from 'components/views/AdminView/selectors';
import { cropOptions, calculatePlacedHivesAmount, mapRanchContactAndManagerOptions } from '../utils';
import BlockList from './BlockList';
import Header from './Header';
import './Ranch.scss';
import usePrepareSubmit from './hooks/usePrepareSubmit';

const USER_TYPES = {
    CONTACT: 'contact',
    RANCH_MANAGER: 'ranchManager',
};

const Ranch = ({ handleOpenAddBlockForm, setBlockEdited, handleRemoveBlock, form }) => {
    const navigate = useNavigate();
    const { id: isEditMode } = useParams();

    const companies = useSelector(getGrowers, arrayOfObjectsShallowEqual);
    const users = useSelector(state => state.adminDashboard.companyUsers, arrayOfObjectsShallowEqual);
    const [ranchManagerOptions, setRanchManagerOptions] = useState([]);
    const [farmManagerOptions, setFarmManagerOptions] = useState([]);
    const [companyOptions, setCompanyOptions] = useState([]);

    const {
        control,
        formState: { isDirty, errors, dirtyFields },
        setError,
        handleSubmit,
        setValue,
    } = form;

    const handleSubmitForm = usePrepareSubmit({ form });

    const errorsList = getRootLevelErrors(errors);
    const [
        companyId,
        blocks,
        crop,
        name,
        contact,
        locations,
        country,
        ranchManagerInvitation,
        contactInvitation,
        companyInvitation,
    ] = useWatch({
        name: [
            'companyId',
            'blocks',
            'crop',
            'name',
            'contact',
            'locations',
            'country',
            'ranchManagerInvitation',
            'contactInvitation',
            'companyInvitation',
        ],
        control,
    });
    const { ranchTotal, blockSums } = calculatePlacedHivesAmount({ blocks, locations });

    const { totalAreaSumByBlocks, totalHivesAmountSumByBlocks } = blocks.reduce(
        (acc, block) => {
            acc.totalAreaSumByBlocks += Number(block.totalArea);
            acc.totalHivesAmountSumByBlocks += Number(block.hivesAmount);
            return acc;
        },
        { totalAreaSumByBlocks: 0, totalHivesAmountSumByBlocks: 0 }
    );

    const modalData = {
        [USER_TYPES.RANCH_MANAGER]: {
            header: 'Add new ranch manager',
        },
        [USER_TYPES.CONTACT]: {
            header: 'Add new contact',
        },
    };

    const handleCreateRanch = data => {
        if (!data.blocks.length) {
            return setError(ROOT_ERRORS.FORM_ERROR, {
                type: 'required',
                message: 'You need to create at least one block',
            });
        }
        handleSubmitForm({ ...data, country });
    };

    const handleAddUserInvitation = name => user => {
        const fieldName = `${name}Invitation`;
        setValue(fieldName, user, { shouldDirty: true });
        setValue(name, constants.INVITED_ID, { shouldDirty: true });
    };

    const handleOpenAddUserModal = name => e => {
        e.preventDefault();
        const { header } = modalData[name];

        modalController.set({
            name: MODALS.ADD_USER,
            props: { name, header, onConfirm: handleAddUserInvitation(name) },
        });
    };

    const handleAddCompanyInvitation = data => {
        setValue('companyInvitation', data, { shouldDirty: true });
        setValue('companyId', constants.INVITED_ID, { shouldDirty: true });
        form.resetField('contact');
        form.resetField('ranchManager');
        setFarmManagerOptions([]);
        setRanchManagerOptions([]);
    };

    const handleOpenAddCompanyModal = e => {
        e.preventDefault();
        modalController.set({
            name: MODALS.ADD_COMPANY,
            props: { onConfirm: handleAddCompanyInvitation },
        });
    };

    const submitButtonText = isEditMode ? 'Update ranch' : 'Create ranch';

    const goBack = () =>
        modalController.set({
            name: MODALS.UNIVERSAL_TEXT_MODAL,
            props: {
                onConfirm: () => navigate('/v1'),
                header: 'Confirmation',
                text: "Are you sure you want to leave this page? Changes won't be saved.",
            },
        });

    useEffect(() => {
        const isCompanyCreated = companyOptions.find(({ value }) => value === constants.INVITED_ID);

        if (isCompanyCreated) {
            setValue('companyId', constants.INVITED_ID, { shouldDirty: true, shouldTouch: true });
        }
        // eslint-disable-next-line
    }, [companyOptions.length, setValue]);

    useEffect(() => {
        if (companyId && farmManagerOptions.length) {
            setValue('contact', farmManagerOptions[0].value, { shouldDirty: true, shouldTouch: true });
        }
    }, [companyId, farmManagerOptions, farmManagerOptions.length, form, setValue]);

    useEffect(() => {
        const options = companies.map(({ id, companyName }) => ({ label: companyName, value: id }));
        if (companyInvitation) {
            options.push({ label: companyInvitation.name, value: constants.INVITED_ID });
        }
        setCompanyOptions(options);
    }, [companies, companyInvitation]);

    useEffect(() => {
        const { farmManagerOptions: contactOptions } = mapRanchContactAndManagerOptions(users);

        if (contactInvitation?.name) {
            contactOptions.push({ label: contactInvitation.name, value: constants.INVITED_ID });
        }

        setFarmManagerOptions(contactOptions);
    }, [contactInvitation?.name, users]);

    useEffect(() => {
        const { ranchManagerOptions } = mapRanchContactAndManagerOptions(users);

        if (ranchManagerInvitation?.name) {
            ranchManagerOptions.push({ label: ranchManagerInvitation.name, value: constants.INVITED_ID });
        }

        setRanchManagerOptions(ranchManagerOptions);
    }, [ranchManagerInvitation?.name, users]);

    return (
        <form className="left-panel-form" autoComplete="off" onSubmit={handleSubmit(handleCreateRanch)}>
            <div className="left-panel-container--scrollable">
                <div className="left-panel-container">
                    {!!errorsList.length && <span className="error error-ranch">{errorsList}</span>}
                    <Header name={name} control={control} />
                    <SelectFormField
                        name="companyId"
                        control={control}
                        label="Company"
                        options={companyOptions}
                        required
                        addNewOption={{ label: '+ Add new company', onClick: handleOpenAddCompanyModal }}
                    />
                    <SelectFormField
                        name="contact"
                        control={control}
                        label="Contact"
                        options={farmManagerOptions}
                        disabled={
                            !companyId || !dirtyFields?.companyId || (contact && companyId !== constants.INVITED_ID)
                        }
                        required
                        addNewOption={{
                            label: '+ Add new contact',
                            onClick: handleOpenAddUserModal(USER_TYPES.CONTACT),
                        }}
                    />
                    <SelectFormField
                        name="ranchManager"
                        control={control}
                        label="Ranch manager"
                        options={ranchManagerOptions}
                        disabled={!companyId}
                        addNewOption={{
                            label: '+ Add new ranch manager',
                            onClick: handleOpenAddUserModal(USER_TYPES.RANCH_MANAGER),
                        }}
                    />
                    <div className="left-panel-form-row">
                        <InputFormField
                            name="totalArea"
                            control={control}
                            label={getUnitsDefinitionFromCountry(country)}
                            type="number"
                            min={0.01}
                            required
                            info={`Blocks total: ${Math.ceil(totalAreaSumByBlocks * 100) / 100}`}
                            pattern={VALIDATIONS.NUMBER}
                        />
                        <InputFormField
                            name="requiredHivesAmount"
                            control={control}
                            label="Req. hives"
                            required
                            type="number"
                            min={1}
                            info={[`Planned total: ${totalHivesAmountSumByBlocks}`, `Actual total: ${ranchTotal}`]}
                            pattern={VALIDATIONS.INTEGER_NUMBER}
                        />
                    </div>

                    <SelectFormField name="crop" control={control} label="Crop" options={cropOptions} required />
                    <InputFormField
                        name="quality"
                        control={control}
                        label="Quality (No. Frames)"
                        pattern={VALIDATIONS.INTEGER_NUMBER}
                    />
                </div>
                {!!blocks.length && (
                    <BlockList
                        blocks={blocks}
                        blockSums={blockSums}
                        handleRemoveBlock={handleRemoveBlock}
                        setBlockEdited={setBlockEdited}
                        country={country}
                    />
                )}
            </div>

            <div className="left-panel-footer-ranch">
                <div className="footer-group">
                    <Button className="primary-orange btn-add" onClick={handleOpenAddBlockForm} disabled={!crop} shadow>
                        + Add block
                    </Button>
                </div>
                <div className="footer-group">
                    <Button className="secondary-blue" onClick={goBack} shadow>
                        Cancel
                    </Button>
                    <Button onClick={noop} className="primary-blue" type="submit" disabled={!isDirty} shadow>
                        {submitButtonText}
                    </Button>
                </div>
            </div>
        </form>
    );
};

Ranch.propTypes = {
    handleOpenAddBlockForm: PropTypes.func,
    setBlockEdited: PropTypes.func,
    handleRemoveBlock: PropTypes.func,
    form: PropTypes.shape(),
};

export default Ranch;
