import React from 'react';
import PropTypes from 'prop-types';
import Button from '@beewise/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import './IconButtonV2.scss';

const IconButtonV2 = React.forwardRef(({ icon, onClick, disabled, isActive, className, ...props }, ref) => (
    <Button
        className={cn('btn-icon btn-icon-v2', className, { 'btn-active': isActive })}
        onClick={onClick}
        disabled={disabled}
        {...props}
    >
        <FontAwesomeIcon icon={icon} ref={ref} />
    </Button>
));

IconButtonV2.propTypes = {
    icon: PropTypes.shape().isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    isActive: PropTypes.bool,
    className: PropTypes.string,
};

export default IconButtonV2;
