import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import { COUNTRY_IL, COUNTRY_US } from 'utils/isIsraeliRanch';
import { fetchCompaniesWithUsers, fetchRanches, fetchUsers } from '../../actions';
import RanchesBlock from './components/RanchesBlock';
import RanchInfo from './components/RanchInfo';
import { getRanches, getSelectedBrokerFilter } from '../../selectors';
import { RanchesListProvider } from './providers';

const Dashboard = () => {
    const dispatch = useDispatch();
    const selectedBrokerFilter = useSelector(getSelectedBrokerFilter);
    const ranches = useSelector(getRanches, arrayOfObjectsShallowEqual);

    useEffect(() => {
        dispatch(fetchCompaniesWithUsers());
        dispatch(fetchUsers({ type: 'broker' }));
        if (selectedBrokerFilter === COUNTRY_US || selectedBrokerFilter === COUNTRY_IL) {
            dispatch(fetchRanches({ country: selectedBrokerFilter }));
        } else {
            dispatch(fetchRanches({ brokerId: selectedBrokerFilter }));
        }
    }, [dispatch, selectedBrokerFilter]);

    return (
        <RanchesListProvider>
            <RanchesBlock ranches={ranches} />
            <RanchInfo />
        </RanchesListProvider>
    );
};

export default Dashboard;
