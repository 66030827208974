import { generateQueryParams } from 'utils/queryParams';
import {
    CREATE_RANCH_DRAFT,
    UPDATE_RANCH_MAIN,
    FETCH_BROKERS,
    UPDATE_RANCH,
    FETCH_RANCHES,
    SET_SELECTED_RANCH,
    DELETE_RANCHES_NEW_FLOW,
    REQUEST_RANCH_APPROVAL,
    SEND_SMS_WITH_COORDINATES,
    FETCH_RANCH_MANAGERS,
    FETCH_COMPANIES_WITH_USERS,
    FETCH_RANCH,
    FETCH_BHOMES,
    FETCH_COMPANY_USERS,
    CREATE_RANCH_MANAGER,
    FETCH_EXISTING_COMPANY_BY_NAME,
    SET_SELECTED_BROKER_FILTER,
    DELETE_RANCH_MANAGER_BY_ID,
    DELETE_COMPANY_BY_ID,
} from '../actionTypes';

export const fetchRanches = (params = {}) => {
    const queryParams = generateQueryParams(params);
    const url = queryParams ? `/ranches/v2?${queryParams}` : '/ranches/v2';

    return {
        type: 'FETCH',
        params: {
            type: FETCH_RANCHES.default,
            url,
            failureToastText: 'Loading ranches error',
        },
    };
};

export const fetchRanch = ({ id, rejector, resolver }) => ({
    type: 'FETCH',
    params: {
        type: FETCH_RANCH.default,
        url: `/ranches/v2/${id}`,
        rejector,
        resolver,
        failureToastText: 'Error fetching ranch',
    },
});

export const fetchCreateRanchDraft = ({ body, resolver, rejector }) => ({
    type: 'FETCH',
    params: {
        method: 'POST',
        type: CREATE_RANCH_DRAFT.default,
        url: '/ranches/v2/draft',
        body,
        resolver,
        rejector,
        failureToastText: 'Error creating ranch',
        toastText: 'Ranch successfully created',
    },
});

export const fetchUpdateRanch = ({ body: { id, ...body }, resolver, rejector }) => ({
    type: 'FETCH',
    params: {
        method: 'PATCH',
        type: UPDATE_RANCH.default,
        url: `/ranches/v2/${id}/details`,
        body,
        resolver,
        rejector,
        failureToastText: 'Error updating ranch',
        toastText: 'Ranch successfully updated',
    },
});

export const fetchUpdateRanchMain = ({ body, resolver, rejector, id }) => ({
    type: 'FETCH',
    params: {
        method: 'PATCH',
        type: UPDATE_RANCH_MAIN.default,
        url: `/ranches/v2/${id}/main`,
        body,
        resolver,
        rejector,
        toastText: 'Ranch successfully updated',
        failureToastText: 'Error updating ranch',
    },
});

export const fetchUsers = ({ type }) => ({
    type: 'FETCH',
    params: {
        method: 'GET',
        type: FETCH_BROKERS.default,
        url: `/users/v2?roles=${type}`,
    },
});

export const setCurrentRanch = item => ({
    type: SET_SELECTED_RANCH,
    item,
});

export const sendSmsWithCoordinates = body => ({
    type: 'FETCH',
    params: {
        method: 'POST',
        type: SEND_SMS_WITH_COORDINATES.default,
        url: '/sms',
        body,
        failureToastText: 'Error sending sms',
        toastText: 'Sms message sent successfully',
    },
});

export const requestRanchApproval = (id, resolver, rejector) => ({
    type: 'FETCH',
    params: {
        method: 'POST',
        type: REQUEST_RANCH_APPROVAL.default,
        url: `/ranches/v2/${id}/approvals`,
        failureToastText: 'Cannot send ranch approval request',
        toastText: 'Ranch approval request has been sent',
        resolver,
        rejector,
    },
});

export const deleteRanchById = (id, resolver) => ({
    type: 'FETCH',
    params: {
        method: 'DELETE',
        type: DELETE_RANCHES_NEW_FLOW.default,
        url: `/ranches/v2/${id}`,
        failureToastText: 'Deleting ranch error',
        toastText: 'Ranch successfully deleted',
        resolver,
    },
});

export const fetchRanchManagers = () => ({
    type: 'FETCH',
    params: {
        method: 'GET',
        type: FETCH_RANCH_MANAGERS.default,
        url: '/users/v2?roles=ranch_manager',
    },
});

export const fetchCompaniesWithUsers = () => ({
    type: 'FETCH',
    params: {
        method: 'GET',
        type: FETCH_COMPANIES_WITH_USERS.default,
        url: '/companies/v2',
    },
});

export const fetchBhomes = () => ({
    type: 'FETCH',
    params: {
        method: 'GET',
        type: FETCH_BHOMES.default,
        url: '/bhomes/ranch-bhomes',
    },
});

export const fetchCompanyUsers = resolver => ({
    type: 'FETCH',
    params: {
        type: FETCH_COMPANY_USERS.default,
        url: '/companies/v2',
        resolver,
    },
});

export const createCompanyUsers = (body, resolver) => ({
    type: 'FETCH',
    params: {
        method: 'POST',
        type: CREATE_RANCH_MANAGER.default,
        url: '/companies/v2',
        failureToastText: 'Error on creation, please check users emails to be unique',
        toastText: 'Company with userd successfully created',
        body,
        resolver,
    },
});

export const createRanchManager = (body, resolver) => ({
    type: 'FETCH',
    params: {
        method: 'POST',
        type: CREATE_RANCH_MANAGER.default,
        url: '/users/v2/ranch-managers',
        failureToastText: 'Error on creation, please check user email to be unique',
        toastText: 'Ranch manager successfully created',
        body,
        resolver,
    },
});

export const updateCompanyUsers = (id, body, resolver) => ({
    type: 'FETCH',
    params: {
        method: 'PATCH',
        type: CREATE_RANCH_MANAGER.default,
        url: `/companies/v2/${id}`,
        failureToastText: 'Error on update, please check users emails to be unique',
        toastText: 'Company with userd successfully updated',
        body,
        resolver,
    },
});

export const updateRanchManager = (id, body, resolver) => ({
    type: 'FETCH',
    params: {
        method: 'PATCH',
        type: CREATE_RANCH_MANAGER.default,
        url: `/users/v2/ranch-managers/${id}`,
        failureToastText: 'Error on update, please check user email to be unique',
        toastText: 'Ranch manager successfully updated',
        body,
        resolver,
    },
});

export const fetchBrokers = resolver => ({
    type: 'FETCH',
    params: {
        type: FETCH_BROKERS.default,
        url: '/users/v2?roles=broker',
        resolver,
    },
});

export const fetchCompanyByName = (name, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_EXISTING_COMPANY_BY_NAME.default,
        url: `/companies/exists?name=${name}`,
        failureToastText: 'Error fetching company by name',
        resolver,
    },
});

export const setSelectedBrokerFilter = item => ({
    type: SET_SELECTED_BROKER_FILTER,
    item,
});

export const deleteRanchManagerById = (id, resolver) => ({
    type: 'FETCH',
    params: {
        method: 'DELETE',
        type: DELETE_RANCH_MANAGER_BY_ID.default,
        url: `/users/v2/ranch-managers/${id}`,
        failureToastText: 'Deleting ranch manager error',
        toastText: 'Ranch manager successfully deleted',
        resolver,
    },
});

export const deleteCompanyById = (id, resolver) => ({
    type: 'FETCH',
    params: {
        method: 'DELETE',
        type: DELETE_COMPANY_BY_ID.default,
        url: `/companies/v2/${id}`,
        failureToastText: 'Deleting company error',
        toastText: 'Company successfully deleted',
        resolver,
    },
});
