import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@beewise/tooltip';
import { formatNumber } from 'components/views/BrokerView/utils';
import './PerformanceItem.scss';

const PerformanceItem = ({ label, value, tooltipText }) => (
    <Tooltip content={tooltipText} position="top">
        <div className="ranches-performance-container-item">
            <span>{formatNumber(value)}</span> {label}
        </div>
    </Tooltip>
);

export default PerformanceItem;

PerformanceItem.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    tooltipText: PropTypes.string,
};
