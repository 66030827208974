import React from 'react';
import { useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';

const ViewField = ({ control, name, Component, ...props }) => {
    const value = useWatch({ control, name });

    return <Component value={value} {...props} />;
};

ViewField.propTypes = {
    Component: PropTypes.elementType,
    name: PropTypes.string,
    control: PropTypes.shape(),
};

export default ViewField;
