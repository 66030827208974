import React from 'react';
import PropTypes from 'prop-types';
import Button, { COLORS, VARIANTS } from '@beewise/button-v2';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import Panel from 'components/reusables/Panel';
import './Footer.scss';

const Footer = ({ onCancel, onDelete, isSelectedRanch }) => (
    <Panel.Footer className="edit-ranch-footer">
        {isSelectedRanch && (
            <Button startIcon={faTrash} color={COLORS.RED} onClick={onDelete}>
                Delete Ranch
            </Button>
        )}
        <div className="edit-ranch-footer-right-block">
            <Button color={COLORS.GRAY} variant={VARIANTS.TEXT} onClick={onCancel}>
                Cancel
            </Button>
            <Button color={COLORS.YELLOW} variant={VARIANTS.CONTAINED} type="submit">
                Save & Continue
            </Button>
        </div>
    </Panel.Footer>
);

Footer.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    isSelectedRanch: PropTypes.bool,
};

export default Footer;
