export const generateQueryParams = (params = {}) => {
    const parts = Object.entries(params).reduce((acc, [key, value]) => {
        if (!value) {
            return acc;
        } else if (Array.isArray(value)) {
            return [...acc, ...value.map(val => `${key}=${val}`)];
        } else {
            return [...acc, `${key}=${value}`];
        }
    }, []);

    return parts.join('&');
};
