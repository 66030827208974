import { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { updateMapBounds } from 'components/reusables/Map/components/Map/utils';
import { createShape, POLYGON_PARAMS, getVisibleMarkers } from 'components/reusables/Map/components/utils';
import constants from 'appConstants';
import { addBeekeeperColorToLocations } from '../../RanchEdit/utils';

const {
    MAP: { ZOOM },
} = constants;

const removeMapShape = shape => {
    window.google?.maps?.event?.clearInstanceListeners(shape);
    shape.setMap(null);
};

const useMap = ({ selectedRanch, ranches, handleSelectCurrentItem, scrollToRanch }) => {
    const mapRef = useRef(null);
    const [map, setMap] = useState(null);
    const [mapOptions, setMapOptions] = useState({});
    const polyShapesRef = useRef([]);

    const isBhomeView = mapOptions?.zoom >= ZOOM.LARGE;

    const visibleRanches = useMemo(
        () => getVisibleMarkers({ markers: ranches, bounds: mapOptions?.bounds }),
        [ranches, mapOptions?.bounds]
    );
    const visibleMarkers = isBhomeView
        ? visibleRanches.flatMap(ranch => addBeekeeperColorToLocations(ranch.locations ?? [], ranch.orders ?? []))
        : visibleRanches;

    const adjustMapZoomAndCenterForSelectedRanch = useCallback(() => {
        const ranchIndex = ranches.findIndex(ranch => ranch.id === selectedRanch?.id);
        const ranch = ranches[ranchIndex];
        if (!ranch) {
            return;
        }
        const points = ranch?.polygons?.flat() ?? [];

        updateMapBounds(map, points);
        scrollToRanch({ scrollToIndex: ranchIndex });
    }, [map, ranches, selectedRanch?.id, scrollToRanch]);

    const adjustMapZoomAndCenterForAllRanches = useCallback(() => {
        const allPoints = ranches.flatMap(ranch => ranch.polygons?.flat() || []);
        updateMapBounds(map, allPoints);
    }, [map, ranches]);

    const createAndAddShape = useCallback(
        ranch => {
            const polygon = createShape({
                ShapeType: window.google.maps.Polygon,
                map,
                params: { ...POLYGON_PARAMS, paths: ranch.polygons },
            });
            polygon.addListener('click', () => {
                handleSelectCurrentItem(ranch);
            });
            return polygon;
        },
        [handleSelectCurrentItem, map]
    );

    useEffect(() => {
        if (!map || !visibleRanches.length || mapOptions?.zoom < ZOOM.MIDDLE) {
            return;
        }

        visibleRanches.forEach(ranch => {
            ranch?.polygons?.length && polyShapesRef.current.push(createAndAddShape(ranch, window.google.maps.Polygon));
        });

        return () => {
            polyShapesRef.current.forEach(removeMapShape);
            polyShapesRef.current = [];
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createAndAddShape, map, visibleRanches, mapOptions?.zoom]);

    useEffect(() => {
        adjustMapZoomAndCenterForAllRanches();
    }, [adjustMapZoomAndCenterForAllRanches, ranches]);

    useEffect(() => {
        adjustMapZoomAndCenterForSelectedRanch();
    }, [adjustMapZoomAndCenterForSelectedRanch, selectedRanch]);

    return {
        map,
        setMap,
        mapRef,
        isBhomeView,
        handleMapChange: setMapOptions,
        visibleMarkers,
    };
};

export default useMap;
