import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import isSignInOrUpView from 'utils/isSignInOrUpView';
import { ROUTE_PATHS } from 'router/constants';

import logo from 'assets/beewise-logo.svg';
import './AdminAppHeader.scss';
import Menu from './Menu';

const AdminAppHeader = ({ isAdminPage }) => {
    const location = useLocation();
    const isSignIn = isSignInOrUpView(location);

    const isV1 = location.pathname.includes('v1');

    return (
        <header className="main-header">
            <div className="logo-container">
                <NavLink to={ROUTE_PATHS.DASHBOARD}>
                    <img className="logo" src={logo} alt="Beewise logo" />
                </NavLink>
                <div className="app-env beta">Beta</div>
            </div>
            {!isSignIn && (
                <>
                    {isAdminPage ? (
                        <div className="nav-list">
                            <NavLink to={ROUTE_PATHS.DASHBOARD} className={cx({ active: false })}>
                                Home
                            </NavLink>
                            {isV1 ? (
                                <>
                                    <NavLink to={ROUTE_PATHS.GROWERS}>Growers</NavLink>
                                    <NavLink to={ROUTE_PATHS.BEEKEEPERS}>Beekeepers</NavLink>
                                    <NavLink to={ROUTE_PATHS.INSTALLERS}>Installers</NavLink>
                                </>
                            ) : (
                                <NavLink to={ROUTE_PATHS.CONTACTS}>Contacts</NavLink>
                            )}
                        </div>
                    ) : (
                        <div className="nav-list">Beewise Pollination Management</div>
                    )}
                    <Menu />
                </>
            )}
        </header>
    );
};

AdminAppHeader.propTypes = {
    isAdminPage: PropTypes.bool,
};

export default AdminAppHeader;
