import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormProvider } from 'react-hook-form';
import LeftPanel from './components/LeftPanel';
import Map from './components/Map';
import useInitStep2FormData from './hooks/useInitStep2FormData';
import useFetchData from './hooks/useFetchData';

const RanchEdit = () => {
    const { id } = useParams();
    const [isFirstStep, setIsFirstStep] = useState(!id);

    const { form } = useInitStep2FormData();

    useFetchData(form);

    return (
        <FormProvider {...form}>
            <LeftPanel setIsFirstStep={setIsFirstStep} isFirstStep={isFirstStep} />
            <Map isToolbarEnabled={!isFirstStep} />
        </FormProvider>
    );
};
export default RanchEdit;
