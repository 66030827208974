import React from 'react';
import Icon from '@beewise/icon';
import './EmptyData.scss';

const EmptyData = () => (
    <div className="empty-data-container">
        <Icon type="empty-box" />
        None assigned
    </div>
);

export default EmptyData;
