import { COUNTRY_US } from 'utils/isIsraeliRanch';
import {
    FETCH_BROKERS,
    CREATE_RANCH_DRAFT,
    FETCH_RANCH_MANAGERS,
    FETCH_COMPANIES_WITH_USERS,
    FETCH_RANCHES,
    SET_SELECTED_RANCH,
    FETCH_RANCH,
    FETCH_BHOMES,
    UPDATE_RANCH_MAIN,
    UPDATE_RANCH,
    DELETE_RANCHES_NEW_FLOW,
    SET_SELECTED_BROKER_FILTER,
} from '../actionTypes';
import { populateRanchesDataModel } from '../utils';

const initialState = {
    companies: [],
    ranchManagers: [],
    ranches: [],
    brokers: [],
    selectedRanch: null,
    bhomes: [],
    selectedBrokerFilter: COUNTRY_US,
};

const brokerView = (state = initialState, action) => {
    const { type, data, item } = action;

    switch (type) {
        // TODO change to new api and structure once ready
        case FETCH_BROKERS.success:
            return {
                ...state,
                brokers: data,
            };
        case CREATE_RANCH_DRAFT.success:
            return {
                ...state,
                ranches: [...state.ranches, data],
                selectedRanch: { ...state.selectedRanch, ...data },
            };
        case UPDATE_RANCH_MAIN.success:
        case UPDATE_RANCH.success:
            return {
                ...state,
                ranches: state.ranches.map(ranch => (ranch.id === data.id ? { ...ranch, ...data } : ranch)),
                selectedRanch: { ...state.selectedRanch, ...data },
            };
        case FETCH_RANCH_MANAGERS.success:
            return {
                ...state,
                ranchManagers: data,
            };
        case FETCH_COMPANIES_WITH_USERS.success:
            return {
                ...state,
                companies: data,
            };
        case FETCH_RANCHES.success:
            return {
                ...state,
                ranches: populateRanchesDataModel(data.ranches),
            };
        case SET_SELECTED_RANCH:
            return {
                ...state,
                selectedRanch: item,
            };
        case FETCH_RANCH.success:
            return {
                ...state,
                selectedRanch: data,
            };
        case FETCH_BHOMES.success:
            return {
                ...state,
                bhomes: data.bhomes,
            };
        case DELETE_RANCHES_NEW_FLOW.success:
            return {
                ...state,
                ranches: state.ranches.filter(({ id }) => data.id !== id),
                selectedRanch: null,
            };
        case SET_SELECTED_BROKER_FILTER:
            return {
                ...state,
                selectedBrokerFilter: item,
            };
        default:
            return state;
    }
};

export default brokerView;
