import React from 'react';
import { SelectFormField, InputFormField, CheckboxFormField } from '@beewise/hook-form';
import PropTypes from 'prop-types';
import Tooltip from '@beewise/tooltip';
import IconButton from '@beewise/icon-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faCircleXmark } from '@fortawesome/pro-regular-svg-icons';

const tooltipContent = (
    <>
        Mark if the beekeeper
        <br /> is his own installer
    </>
);

const EditBeekeeper = ({ control, remove, index, options }) => (
    <fieldset className="beekeepers-edit-row">
        <SelectFormField name={`orders.${index}.beekeeperId`} control={control} label="Beekeeper" options={options} />
        <InputFormField name={`orders.${index}.hivesAmount`} control={control} label="Number of hives" />
        <CheckboxFormField name={`orders.${index}.isInstaller`} control={control} label="Is installer" />
        <Tooltip content={tooltipContent} position="top center">
            <FontAwesomeIcon icon={faInfoCircle} />
        </Tooltip>
        <IconButton icon={faCircleXmark} onClick={remove} />
    </fieldset>
);

EditBeekeeper.propTypes = {
    control: PropTypes.shape().isRequired,
    remove: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default EditBeekeeper;
