import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@beewise/icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/pro-light-svg-icons';
import { pluralizeNoun } from 'utils/pluralizeNoun';
import EmptyData from './EmptyData';
import RanchDetailsItem from './RanchDetailsItem';
import './RanchDetails.scss';

const RanchDetails = ({ currentRanch }) => {
    if (!currentRanch) {
        return null;
    }

    return (
        <div className="ranch-details-container">
            <RanchDetailsItem title="Details">
                <div className="ranch-details-container-item-block-info">
                    <div className="ranch-details-container-item-block-info-icon">
                        <Icon type="contact" />
                    </div>
                    {currentRanch.brokerName ?? '-'}
                </div>
                <div className="ranch-details-container-item-block-info">
                    <div className="ranch-details-container-item-block-info-icon">
                        <FontAwesomeIcon icon={faPhone} />
                    </div>
                    {currentRanch.brokerInfo?.username ?? '-'}
                </div>
                <div className="ranch-details-container-item-block-info">
                    <div className="ranch-details-container-item-block-info-icon">
                        <Icon type="place" />
                    </div>
                    {currentRanch.totalPlacedHives ? (
                        <>
                            Placed standard hives: {currentRanch.totalPlacedHives}
                            <br />
                            Placed beehomes: {currentRanch.totalBhomes}
                        </>
                    ) : (
                        'No hives were placed'
                    )}
                </div>
            </RanchDetailsItem>
            <RanchDetailsItem icon={<Icon type="installer" />} title="Installers">
                {currentRanch?.installersInfo?.length ? (
                    currentRanch.installersInfo.map(item => (
                        <div key={item.id} className="ranch-details-container-item-block-info">
                            {item.name}
                        </div>
                    ))
                ) : (
                    <EmptyData />
                )}
            </RanchDetailsItem>
            <RanchDetailsItem icon={<Icon type="beekeeper" />} title="Beekeepers">
                {currentRanch?.orders?.length ? (
                    currentRanch.orders.map(order => (
                        <div key={order.id} className="ranch-details-container-item-block-beekeeper">
                            <span style={{ backgroundColor: order.beekeeperColor }} />
                            <div className="ranch-details-container-item-block-beekeeper-details">
                                {order.beekeeperName}
                                <div className="ranch-details-container-item-block-beekeeper-details-separator">|</div>
                                <span>
                                    {order.beekeeperHivesSum} {pluralizeNoun(order.beekeeperHivesSum, 'hive')}
                                </span>
                            </div>
                        </div>
                    ))
                ) : (
                    <EmptyData />
                )}
            </RanchDetailsItem>
        </div>
    );
};

export default RanchDetails;

RanchDetails.propTypes = {
    currentRanch: PropTypes.shape(),
};
