import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@beewise/icon-button';
import { faPencil, faXmark } from '@fortawesome/pro-light-svg-icons';
import './EditableListItem.scss';

const EditableListItem = ({ handleEdit, handleRemove, children }) => (
    <div className="editable-list-item">
        <div className="editable-list-item-content">{children}</div>
        <IconButton icon={faPencil} onClick={handleEdit} />
        {handleRemove && <IconButton icon={faXmark} onClick={handleRemove} />}
    </div>
);

EditableListItem.propTypes = {
    handleEdit: PropTypes.func.isRequired,
    handleRemove: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
};

export default EditableListItem;
