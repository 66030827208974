import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '@beewise/icon';
import { SelectField } from '@beewise/select-field';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import { COUNTRY_IL, COUNTRY_US } from 'utils/isIsraeliRanch';
import { getBrokers, getSelectedBrokerFilter } from 'components/views/BrokerView/selectors';
import { fetchRanches, setSelectedBrokerFilter } from 'components/views/BrokerView/actions';
import { groupBrokersByCountry } from '../../../utils';
import './RanchesFiltering.scss';

const RanchesFiltering = () => {
    const dispatch = useDispatch();
    const selectedBrokerFilter = useSelector(getSelectedBrokerFilter);
    const brokers = useSelector(getBrokers, arrayOfObjectsShallowEqual);
    const groupedBrokersOptions = groupBrokersByCountry(brokers);

    const [selectedOption, setSelectedOption] = useState(selectedBrokerFilter);

    const handleFilterChange = selectedValue => {
        setSelectedOption(selectedValue);
        dispatch(setSelectedBrokerFilter(selectedValue));
        if (selectedValue === COUNTRY_US || selectedValue === COUNTRY_IL) {
            dispatch(fetchRanches({ country: selectedValue }));
        } else {
            dispatch(fetchRanches({ brokerId: selectedValue }));
        }
    };

    return (
        <div className="ranches-filtering">
            <Icon type="bee-simple" className="ranches-filtering-icon" />
            <SelectField
                className="search-select-field"
                options={groupedBrokersOptions}
                value={selectedOption}
                onChange={handleFilterChange}
            />
        </div>
    );
};

export default RanchesFiltering;
