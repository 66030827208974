import { useCallback } from 'react';
import { groupBy, omit } from 'lodash-es';
import { useNavigate, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import useSubmitForm from 'utils/hooks/useSubmitForm';
import { convertArea } from 'utils/isIsraeliRanch';
import constants from 'appConstants';
import { modalController } from 'components/reusables/ModalManager/modalController';
import { MODALS } from 'components/reusables/ModalManager/config';
import { createRanch, updateRanch } from 'components/views/AdminView/views/BrokerRanch/actions';
import { getSelectedItem } from 'components/views/AdminView/selectors';
import {
    getExtraFieldsForCreateMode,
    getExtraFieldsForEditMode,
    getInvitationsAndCompanyData,
    mapLocations,
} from './utils';

const fieldsToStringify = ['trees', 'polygon', 'accessRoadCoordinates'];

const usePrepareSubmit = ({ form }) => {
    const { id } = useParams();

    const selectedRanch = useSelector(getSelectedItem, shallowEqual);

    const navigate = useNavigate();

    const isEditMode = !!id;

    const dispatch = useDispatch();

    const prepareData = useCallback(
        state => {
            const locations = mapLocations(state.locations);
            const invitationsAndCompanyData = getInvitationsAndCompanyData(state);

            const extraFields = isEditMode
                ? getExtraFieldsForEditMode(locations, state)
                : getExtraFieldsForCreateMode(locations, state);

            return {
                ...omit(state, ['locations', 'contact']),
                totalArea: convertArea(state.totalArea, state.country, true),
                ...invitationsAndCompanyData,
                ...extraFields,
            };
        },
        [isEditMode]
    );

    const preSubmit = data => {
        const locationsWithoutBlockChange = data.locationsChange?.filter(
            location => !location.blockId?.includes(constants.NEW_BLOCK_PREFIX)
        );
        if (locationsWithoutBlockChange?.length !== data.locationsChange?.length) {
            const locationsWithBlockAssignmentChange = data.locationsChange.filter(location =>
                location.blockId.includes(constants.NEW_BLOCK_PREFIX)
            );
            const locationsWithBlockAssignmentChangeByBlockId = groupBy(locationsWithBlockAssignmentChange, 'blockId');
            return {
                ...omit(data, 'locationsChange'),
                blocksAdd: data.blocksAdd.map(block => {
                    if (locationsWithBlockAssignmentChangeByBlockId[block.name]) {
                        return {
                            ...block,
                            locationsChange: locationsWithBlockAssignmentChangeByBlockId[block.name],
                        };
                    }
                    return block;
                }),
                ...(locationsWithoutBlockChange.length ? { locationsChange: locationsWithoutBlockChange } : {}),
            };
        }
        return data;
    };

    const onSubmit = isEditMode ? updateRanch(id) : createRanch;

    const goBack = () => navigate('/v1');

    const validateDirtyFields = useCallback(
        ({ dirtyFieldsToSubmit, handleSetError }) => {
            const isLocationPositionChanged = dirtyFieldsToSubmit?.locationsChange?.some(
                ({ lat, lng, hivesAmount }) => lat || lng || hivesAmount
            );
            const isBlockPolygonChanged = dirtyFieldsToSubmit?.blocksChange?.some(({ polygon }) => polygon);
            if (
                selectedRanch?.orders?.length &&
                (dirtyFieldsToSubmit?.blocksAdd ||
                    dirtyFieldsToSubmit?.blocksRemove ||
                    dirtyFieldsToSubmit?.locationsRemove ||
                    dirtyFieldsToSubmit?.locationsAdd ||
                    isLocationPositionChanged ||
                    isBlockPolygonChanged)
            ) {
                modalController.set({
                    name: MODALS.UNIVERSAL_TEXT_MODAL,
                    props: {
                        onConfirm: () => dispatch(onSubmit(dirtyFieldsToSubmit, goBack, handleSetError)),
                        onCloseCustom: goBack,
                        header: 'Update ranch',
                        text: 'Updating the drop layout requires reassigning beekeepers to this ranch',
                        confirmationText: 'Update',
                    },
                });
                return false;
            }
            return true;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedRanch?.orders]
    );

    return useSubmitForm({
        form,
        onSubmit,
        isEditMode,
        preSubmit,
        fieldsToStringify,
        prepareData,
        resolver: goBack,
        validateDirtyFields,
    });
};

export default usePrepareSubmit;
