import constants from 'appConstants';

export const getDefaultValues = defaultValuesInput => {
    const defaultValues = defaultValuesInput ?? {};

    return {
        companyId: defaultValues.id ?? '',
        brokerIds: Array.isArray(defaultValues.brokers)
            ? defaultValues.brokers.map(broker => broker?.id).filter(Boolean)
            : [],
        companyName: defaultValues.name ?? '',
        companyType: Array.isArray(defaultValues.type) ? (defaultValues.type[0] ?? '') : (defaultValues.type ?? ''),
        hivesAmount: defaultValues.data?.hivesAmount ?? '',
        users:
            Array.isArray(defaultValues.users) && defaultValues.users.length
                ? defaultValues.users.map(user => ({
                      id: user?.id ?? '',
                      phone: user?.phone ?? '',
                      username: user?.username ?? '',
                      email: user?.email ?? '',
                  }))
                : [
                      {
                          phone: '',
                          username: '',
                          email: '',
                      },
                  ],
    };
};

export const getRanchManagerPayload = data => {
    const user = data.users[0];

    return {
        brokerIds: data.brokerIds,
        phone: user.phone,
        username: user.username,
        email: user.email,
        role: constants.ROLES.RANCH_MANAGER,
    };
};

export const getCompanyUsersPayload = (data, isEditMode) => {
    const company = {
        name: data.companyName,
        brokerIds: data.brokerIds,
        type: [data.companyType],
        ...(data.companyType === constants.COMPANY_TYPES.BEEKEEPER &&
            data.hivesAmount && { hivesAmount: Number(data.hivesAmount) }),
    };

    const users = data.users.map(user => ({
        phone: user.phone,
        username: user.username,
        email: user.email,
        ...(isEditMode && { id: user.id }),
    }));

    return { company, users };
};
