import { useState } from 'react';
import useDrawingMode from './useDrawingMode';
import useMarkerMode from './useMarkerMode';
import useCreateInitialFigures from './useCreateInitialFigures';

const useMap = ({
    addPolygon,
    addAccessRoad,
    removePolygon,
    updatePolygon,
    isToolbarEnabled,
    createLocation,
    removeLocation,
    updateLocation,
    form,
}) => {
    const [mode, setMode] = useState(null);

    const { handleModeChange, onGoogleApiLoaded, map, polygonsRef } = useDrawingMode({
        addPolygon,
        addAccessRoad,
        mode,
        isToolbarEnabled,
        removePolygon,
        updatePolygon,
        setMode,
    });
    useCreateInitialFigures({ map, polygonsRef });
    const { handleMarkerModeChange, handleEditLocationMarker, isDraggingRef } = useMarkerMode({
        map,
        mode,
        polygonsRef,
        createLocation,
        removeLocation,
        updateLocation,
        form,
    });
    const onModeChange = mode => {
        handleModeChange(mode);
        handleMarkerModeChange(mode);
        setMode(mode);
    };

    return { mode, onModeChange, map, polygonsRef, isDraggingRef, handleEditLocationMarker, onGoogleApiLoaded };
};

export default useMap;
