import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Icon from '@beewise/icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VARIANTS, SIZES, COLORS } from './utils';
import './ButtonV2.scss';

const renderIcon = (icon) => {
  if (!icon) return null;
  return typeof icon === 'string' ? (
    <Icon type={icon} className="button-icon" />
  ) : (
    <FontAwesomeIcon icon={icon} className="button-icon" />
  );
};

const ButtonV2Component = forwardRef(
  (
    {
      className,
      children,
      onClick,
      variant = VARIANTS.TEXT,
      size = SIZES.MEDIUM,
      color = COLORS.DEFAULT,
      disabled = false,
      startIcon,
      endIcon,
      ...props
    },
    ref
  ) => {
    const buttonClass = cx('btn v2', className, {
      [`btn-${variant}`]: variant,
      [`btn-${size}`]: size,
      [`btn-${color}`]: color,
      'btn-disabled': disabled
    });

    return (
      <button
        type="button"
        className={buttonClass}
        ref={ref}
        onClick={onClick}
        disabled={disabled}
        {...props}
      >
        {renderIcon(startIcon)}
        {children}
        {renderIcon(endIcon)}
      </button>
    );
  }
);

ButtonV2Component.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  size: PropTypes.oneOf(Object.values(SIZES)),
  color: PropTypes.oneOf(Object.values(COLORS)),
  disabled: PropTypes.bool,
  startIcon: PropTypes.shape(),
  endIcon: PropTypes.shape()
};

export default ButtonV2Component;
