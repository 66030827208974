import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isArraysEqualEps } from '../utils/utils';
import MapMarkers from './markers';

function MapComponent({
  children = null,
  style = {
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    margin: 0,
    padding: 0,
    position: 'absolute'
  },
  defaultCenter,
  defaultZoom,
  onGoogleApiLoaded,
  onChange,
  options = {},
  events = []
}) {
  const mapRef = useRef(null);
  const prevBoundsRef = useRef([]);
  const [map, setMap] = useState();
  const [maps, setMaps] = useState();
  const [googleApiCalled, setGoogleApiCalled] = useState(false);

  const onIdle = useCallback(() => {
    try {
      if (!map) {
        return;
      }

      const zoom = map.getZoom() ?? defaultZoom;
      const bounds = map.getBounds();
      const centerLatLng = [map.getCenter()?.lng(), map.getCenter()?.lat()];

      const ne = bounds?.getNorthEast();
      const sw = bounds?.getSouthWest();

      if (!ne || !sw || !bounds) {
        return;
      }

      // east, north, south, west
      const boundsArray = [sw.lng(), sw.lat(), ne.lng(), ne.lat()];

      if (!isArraysEqualEps(boundsArray, prevBoundsRef.current)) {
        if (onChange) {
          onChange({
            zoom,
            center: centerLatLng,
            bounds
          });
        }
        prevBoundsRef.current = boundsArray;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [map, onChange]);

  useEffect(() => {
    if (mapRef.current && !map) {
      setMap(
        new google.maps.Map(mapRef.current, {
          center: defaultCenter,
          zoom: defaultZoom,
          ...options
        })
      );
      setMaps(google.maps);
    }
  }, [defaultCenter, defaultZoom, map, mapRef, options]);

  useEffect(() => {
    if (map && !googleApiCalled) {
      if (typeof onGoogleApiLoaded === 'function' && maps) {
        onGoogleApiLoaded({ map, maps, ref: mapRef.current });
      }
      setGoogleApiCalled(true);

      if (google.maps.event.hasListeners(map, 'idle'))
        google.maps.event.clearListeners(map, 'idle');
      // Idle event is fired when the map becomes idle after panning or zooming.
      google.maps.event.addListener(map, 'idle', onIdle);
    }
  }, [googleApiCalled, map, onGoogleApiLoaded]);

  useEffect(
    () =>
      // clear listeners on unmount
      () => {
        if (map) {
          google.maps.event.clearListeners(map, 'idle');
        }
      },
    [map]
  );

  return (
    <>
      <div
        ref={mapRef}
        style={style}
        className="google-map"
        // spread the events as props
        {...events?.reduce((acc, { name, handler }) => {
          acc[name] = handler;
          return acc;
        }, {})}
      />
      {children && map && maps && (
        <MapMarkers map={map} maps={maps}>
          {children}
        </MapMarkers>
      )}
    </>
  );
}

export default MapComponent;
