/* eslint-disable */
export const isArraysEqualEps = (arrayA, arrayB, eps = 0.000001) => {
  if (arrayA.length && arrayB.length) {
    for (let i = 0; i !== arrayA.length; ++i) {
      if (Math.abs(arrayA[i] - arrayB[i]) > eps) {
        return false;
      }
    }
    return true;
  }
  return false;
};
