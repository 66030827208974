import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const OutsideClickHandler = ({ children, onOutsideClick, className }) => {
    const ref = useRef(null);

    useEffect(() => {
        const handler = e => {
            if (!ref.current?.contains(e.target)) {
                onOutsideClick();
            }
        };
        document.addEventListener('mousedown', handler);
        return () => document.removeEventListener('mousedown', handler);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div ref={ref} className={className}>
            {children}
        </div>
    );
};

OutsideClickHandler.propTypes = {
    children: PropTypes.node.isRequired,
    onOutsideClick: PropTypes.func.isRequired,
    className: PropTypes.string,
};

export default OutsideClickHandler;
