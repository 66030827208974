import React from 'react';
import { useSelector } from 'react-redux';
import { shallowEqual } from '@beewise/react-utils';
import { getCurrentRanch } from 'components/views/BrokerView/selectors';
import RanchHeader from './RanchHeader';
import RanchDetails from './RanchDetails';
import MapContainer from './MapContainer';
import './RanchInfo.scss';

const RanchInfo = () => {
    const currentRanch = useSelector(getCurrentRanch, shallowEqual);

    return (
        <div className="ranch-container">
            <RanchHeader currentRanch={currentRanch} />
            <MapContainer currentRanch={currentRanch} />
            <RanchDetails currentRanch={currentRanch} />
        </div>
    );
};

export default RanchInfo;
