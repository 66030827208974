import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Menu from '@beewise/menu';
import Button from '@beewise/button-v2';
import constants from 'appConstants';
import { formatDateToShortString } from 'utils/formatDateToShortString';
import { modalController } from 'components/reusables/ModalManager/modalController';
import { MODALS } from 'components/reusables/ModalManager/config';
import { deleteRanchById, requestRanchApproval, sendSmsWithCoordinates } from 'components/views/BrokerView/actions';
import { getLoadingZoneCoordinates } from '../../../utils';
import './RanchHeader.scss';

const RanchHeader = ({ currentRanch }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onCancel = () => {
        navigate('/');
    };

    const threeDotMenu = [
        {
            label: 'Grower dashboard',
            onClick: () => window.open(`/report/${currentRanch.id}`, '_blank'),
        },
        {
            label: 'Send location',
            onClick: () =>
                modalController.set({
                    name: MODALS.SEND_RANCH_LOCATION,
                    props: {
                        onConfirm: ({ contactName, phoneNumber }) => {
                            const coordinates = getLoadingZoneCoordinates({ ranch: currentRanch });
                            dispatch(sendSmsWithCoordinates({ contactName, phoneNumber, coordinates }));
                        },
                    },
                }),
        },
        {
            label: 'Layout approval',
            onClick: () => dispatch(requestRanchApproval(currentRanch.id)),
            ...(currentRanch?.approvalStatus
                ? {
                      tooltip: `Approval is in status: ${
                          currentRanch?.approvalStatus || constants.RANCH_APPROVAL_STATUSES.REQUESTED
                      }`,
                  }
                : {}),
        },
        {
            label: 'Delete ranch',
            onClick: () =>
                modalController.set({
                    name: MODALS.DELETE_RANCH,
                    props: {
                        onConfirm: () =>
                            deleteRanchById(currentRanch.id, () => {
                                navigate('/');
                            }),
                        onClose: onCancel,
                        ranchName: currentRanch.name,
                    },
                }),
            className: 'red-item',
        },
    ];

    const navigateEditRanch = () => navigate(`/ranch/${currentRanch.id}`);

    if (!currentRanch) {
        return null;
    }

    return (
        <div className="ranch-header-container">
            <div className="ranch-header-container-block">
                <div className="ranch-header-container-block-title">{currentRanch.name}</div>
                <div className="ranch-header-container-block-data ranch-header-container-block-text">
                    {currentRanch.requiredHivesAmount} Contracted
                </div>
            </div>
            <div className="ranch-header-container-block">
                {currentRanch?.approvedAt && (
                    <div className="ranch-header-container-block-text">
                        {formatDateToShortString(currentRanch.approvedAt)} approved
                    </div>
                )}
                <div className="ranch-header-container-block-manage">
                    <Button color="yellow" variant="contained" onClick={navigateEditRanch}>
                        Manage Ranch
                    </Button>
                    <Menu
                        className="ranch-header-container-block-manage-menu"
                        options={threeDotMenu}
                        position="bottom right"
                        iconType="secondary"
                    />
                </div>
            </div>
        </div>
    );
};

export default RanchHeader;

RanchHeader.propTypes = {
    currentRanch: PropTypes.shape(),
};
