import React from 'react';
import Icon from '@beewise/icon';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const IconSvg = ({ icon, className }) =>
    typeof icon === 'string' ? (
        <Icon type={icon} className={className} />
    ) : (
        <FontAwesomeIcon icon={icon} className={className} />
    );

IconSvg.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    className: PropTypes.string,
};

export default IconSvg;
