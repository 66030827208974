import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import MapV2 from 'components/reusables/Map/components/MapV2';
import MapInfo from 'components/reusables/Map/components/MapInfo';
import { getRanches } from 'components/views/BrokerView/selectors';
import RanchMarkerV2 from 'components/reusables/Map/components/RanchMarkerV2';
import LocationMarkerv2 from 'components/reusables/Map/components/LocationMarkerv2';
import { setCurrentRanch } from 'components/views/BrokerView/actions';
import useMap from '../../../hooks/useMap';
import { useRanchesList } from '../../../providers';

const MapContainer = ({ currentRanch }) => {
    const ranches = useSelector(getRanches, arrayOfObjectsShallowEqual);
    const dispatch = useDispatch();
    const { scrollToRanch } = useRanchesList();

    const handleSetCurrentRanch = ranch => {
        dispatch(setCurrentRanch(ranch));
    };

    const mapConfig = {
        ranches,
        selectedRanch: currentRanch,
        handleSelectCurrentItem: handleSetCurrentRanch,
        scrollToRanch,
    };
    const { setMap, isBhomeView, handleMapChange, visibleMarkers } = useMap(mapConfig);
    const MarkerComponent = isBhomeView ? LocationMarkerv2 : RanchMarkerV2;

    return (
        <MapV2
            onGoogleApiLoaded={({ map }) => {
                setMap(map);
            }}
            extraOptions={<MapInfo />}
            handleMapChange={handleMapChange}
        >
            {visibleMarkers.map(marker => (
                <MarkerComponent
                    key={marker.id}
                    marker={marker}
                    lat={marker.lat}
                    lng={marker.lng}
                    handleMarkerClick={handleSetCurrentRanch}
                />
            ))}
        </MapV2>
    );
};

export default MapContainer;

MapContainer.propTypes = {
    currentRanch: PropTypes.shape(),
};
