import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Tooltip from '@beewise/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { pluralizeNoun } from 'utils/pluralizeNoun';
import { getCurrentRanch } from 'components/views/BrokerView/selectors';
import { setCurrentRanch } from 'components/views/BrokerView/actions';
import { RanchType } from '../types';
import './RanchItem.scss';

const RanchItem = ({ data: { ranches }, index, style }) => {
    const dispatch = useDispatch();

    const ranch = ranches[index];
    const currentRanch = useSelector(getCurrentRanch, shallowEqual);
    const isActiveRanch = ranch?.id === currentRanch?.id;

    if (!ranch) {
        return null;
    }

    const {
        name,
        requiredHivesAmount = 0,
        totalPlannedHives = 0,
        totalAssignedHives = 0,
        totalPlacedHives = 0,
    } = ranch;

    const ranchDetails = [
        { label: 'Contracted:', value: requiredHivesAmount, showCheckmark: false },
        {
            label: 'Planned:',
            value: totalPlannedHives,
            showCheckmark: totalPlannedHives === requiredHivesAmount,
            isRedData: totalPlannedHives > requiredHivesAmount,
            tooltipMessage: (
                <div className="tooltip-left-align">
                    Planned {totalPlannedHives - requiredHivesAmount}{' '}
                    {pluralizeNoun(totalPlannedHives - requiredHivesAmount, 'hive')}
                    <br />
                    more than contracted
                </div>
            ),
        },
        { label: 'Assigned:', value: totalAssignedHives, showCheckmark: totalAssignedHives === requiredHivesAmount },
        {
            label: 'Placed:',
            value: totalPlacedHives,
            showCheckmark: totalPlacedHives === requiredHivesAmount,
            isRedData: totalPlacedHives > requiredHivesAmount,
            tooltipMessage: (
                <div className="tooltip-left-align">
                    {totalPlacedHives - requiredHivesAmount}{' '}
                    {pluralizeNoun(totalPlacedHives - requiredHivesAmount, 'hive')} were placed in a
                    <br />
                    different location then planned
                </div>
            ),
        },
    ];

    const handleSetCurrentRanch = () => dispatch(setCurrentRanch(ranch));

    return (
        <div style={style} className="ranch-item" onClick={handleSetCurrentRanch} role="presentation">
            <div className={cx('ranch-item-content', { 'active-ranch': isActiveRanch })}>
                <div className="ranch-item-content-top">{name}</div>
                <div className="ranch-item-content-bottom">
                    {ranchDetails.map(({ label, value, showCheckmark, isRedData, tooltipMessage }, index) => (
                        <div className="ranch-item-content-bottom-item" key={`${label}-${index}`}>
                            <span>{label}</span>
                            <Tooltip
                                text={isRedData ? tooltipMessage : ''}
                                disabled={!isRedData}
                                inverted
                                position="top"
                            >
                                <div className={cx('ranch-item-content-bottom-item-number', { 'red-data': isRedData })}>
                                    {value}
                                </div>
                            </Tooltip>
                            {showCheckmark && <FontAwesomeIcon icon={faCheck} />}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

RanchItem.propTypes = {
    data: PropTypes.shape({
        ranches: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape(RanchType)), PropTypes.array]),
    }).isRequired,
    index: PropTypes.number.isRequired,
    style: PropTypes.shape({}).isRequired,
};

export default RanchItem;
