import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import Menu from '@beewise/menu';
import './AccordionCustom.scss';

export const ARROW_POSITION = {
  LEFT: 'left',
  RIGHT: 'right'
};

const ArrowButton = ({ onToggle }) => (
  <div className="accordion-icon" onKeyDown={onToggle} role="button" tabIndex={0}>
    <FontAwesomeIcon icon={faAngleDown} className="icon" />
  </div>
);

ArrowButton.propTypes = {
  onToggle: PropTypes.func.isRequired
};

const AccordionCustom = forwardRef(
  (
    {
      isOpen = false,
      onToggle,
      title,
      description,
      children,
      className,
      threeDotMenu,
      style,
      color = 'white',
      arrowPosition = ARROW_POSITION.LEFT
    },
    ref
  ) => (
    <div className={cn('accordion', className, { open: isOpen })} style={style} ref={ref}>
      <div className={cn('accordion-header', color, { open: isOpen })}>
        <div
          className="accordion-header-section"
          onClick={onToggle}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              onToggle();
            }
          }}
          role="button"
          tabIndex="0"
        >
          {arrowPosition === ARROW_POSITION.LEFT && <ArrowButton onToggle={onToggle} />}
          <span className="accordion-title">{title}</span>
          {description &&
            (typeof description === 'string' ? (
              <span className="accordion-description">{description}</span>
            ) : (
              description
            ))}
        </div>
        {!!threeDotMenu?.length && (
          <div className="accordion-menu">
            <Menu options={threeDotMenu} />
          </div>
        )}
        {arrowPosition === ARROW_POSITION.RIGHT && <ArrowButton onToggle={onToggle} />}
      </div>
      {isOpen && children}
    </div>
  )
);

AccordionCustom.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  threeDotMenu: PropTypes.arrayOf(PropTypes.shape({})),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  style: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func
  }),
  color: PropTypes.oneOf(['white', 'purple']),
  arrowPosition: PropTypes.oneOf(Object.values(ARROW_POSITION))
};

export default AccordionCustom;
