import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { getSelectedRanch } from 'components/views/BrokerView/selectors';
import ViewField from 'components/reusables/Form/ViewField';
import { faNote, faLocationDot } from '@fortawesome/pro-light-svg-icons';
import constants from 'appConstants';

import Icon from 'components/reusables/Icon';
import './PerformanceWidget.scss';

const WidgetItem = ({ value, title, icon, valueGetter, descriptionValueGetter }) => {
    const calculatedValue = valueGetter ? valueGetter(value) : value;
    return (
        <div className="performance-widget__item">
            <div className="row">
                <div className="performance-widget__item-name">{title}</div>
                <Icon icon={icon} className="performance-widget__item-icon" />
            </div>
            <div className="performance-widget__value_wrapper">
                <h1 className="performance-widget__item-value">{calculatedValue}</h1>
                <span className="performance-widget__item-description">
                    {descriptionValueGetter?.(calculatedValue)}
                </span>
            </div>
        </div>
    );
};

WidgetItem.propTypes = {
    value: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    icon: PropTypes.shape().isRequired,
    valueGetter: PropTypes.func,
    descriptionValueGetter: PropTypes.func,
};

const getHivesAmount = items => items?.reduce((acc, item) => acc + Number(item.hivesAmount), 0) ?? 0;
const getPlacedHivesAmount = locations =>
    locations?.reduce((acc, location) => {
        const standardHivesAmount =
            location.standardHives?.reduce((sum, hive) => sum + Number(hive.hivesAmount ?? 0), 0) ?? 0;

        acc += standardHivesAmount;

        if (location.bhomes) {
            acc += location.bhomes.length * constants.DEFAULT_BHOME_HIVES_AMOUNT;
        }

        return acc;
    }, 0) ?? 0;

const PerformanceWidget = ({ control }) => {
    const contractedHives = useSelector(getSelectedRanch, shallowEqual)?.requiredHivesAmount;
    const getDescriptionValue = value => `(${contractedHives - value} left)`;

    return (
        <section className="performance-widget">
            <WidgetItem value={contractedHives} title="Contracted" icon={faNote} />
            <ViewField
                control={control}
                Component={WidgetItem}
                name="locations"
                title="Planned"
                icon={faLocationDot}
                valueGetter={getHivesAmount}
                descriptionValueGetter={getDescriptionValue}
            />
            <ViewField
                control={control}
                Component={WidgetItem}
                name="orders"
                title="Assigned"
                icon="beekeeper"
                valueGetter={getHivesAmount}
                descriptionValueGetter={getDescriptionValue}
            />
            <ViewField
                control={control}
                Component={WidgetItem}
                name="locations"
                title="Placed"
                icon="place"
                valueGetter={getPlacedHivesAmount}
                descriptionValueGetter={getDescriptionValue}
            />
        </section>
    );
};

PerformanceWidget.propTypes = {
    control: PropTypes.shape().isRequired,
};

export default PerformanceWidget;
