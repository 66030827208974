import { createSelector } from 'reselect';
import { getRanchManagers } from '../../../selectors';

export const getCompanyUsers = state => state.contacts.companyUsers;

export const getContactsData = createSelector([getCompanyUsers, getRanchManagers], (companyUsers, ranchManagers) => {
    const companyRows = companyUsers.map(company => ({
        id: company.id,
        name: company.name,
        type: company.type.toString(),
        users: company.users,
        data: company.data,
        brokers: company.brokers,
    }));

    const ranchManagerRows = ranchManagers.map(ranchManager => ({
        id: ranchManager.id,
        name: ranchManager.username,
        type: ranchManager.roleName,
        users: [
            {
                username: ranchManager.username,
                email: ranchManager.email,
                phone: ranchManager.phone,
            },
        ],
        data: {},
        brokers: ranchManager.brokers,
    }));

    return [...companyRows, ...ranchManagerRows];
});
