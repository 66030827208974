import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getSelectedRanch } from 'components/views/BrokerView/selectors';
import { getDefaultValuesStep2 } from '../utils';

const useInitStep2FormData = () => {
    const selectedRanch = useSelector(getSelectedRanch);

    const form = useForm({
        defaultValues: getDefaultValuesStep2(selectedRanch),
    });

    return { form };
};

export default useInitStep2FormData;
