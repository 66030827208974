export const ROUTE_PATHS = {
    HOME: '/v1',
    SIGNIN: '/signin',
    SETUP_PASSWORD: '/setup-password',
    RANCH: '/v1/ranch',
    GROWERS: '/growers',
    BEEKEEPERS: '/beekeepers',
    INSTALLERS: '/installers',
    CONFERENCE: '/conf',
    REPORT: '/report',
    DASHBOARD: '/',
    RANCH_EDIT: '/ranch',
    COMPANIES: '/companies',
    CONTACTS: '/contacts',
};
