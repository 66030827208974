const UUID_LENGTH = 36;

export const filterContacts = ({ contactsData, selectedRoles, selectedBrokers, searchQuery }) => {
    const query = searchQuery.trim().toLowerCase();

    return contactsData?.filter(contact => {
        if (selectedRoles.length) {
            const hasRole = selectedRoles.includes(contact.type);

            if (!hasRole) {
                return false;
            }
        }

        if (selectedBrokers.length) {
            const contactBrokerIds = (contact.brokers || []).map(broker => broker.id);
            const hasBroker = contactBrokerIds.some(id => selectedBrokers.includes(id));

            if (!hasBroker) {
                return false;
            }
        }

        if (query) {
            const companyName = contact.name || '';
            const companyNameMatch = companyName.toLowerCase().includes(query);

            const users = contact.users || [];
            const emailMatch = users.some(user => (user.email || '').toLowerCase().includes(query));
            const phoneMatch = users.some(user => (user.phone || '').toLowerCase().includes(query));

            if (!companyNameMatch && !emailMatch && !phoneMatch) {
                return false;
            }
        }

        return true;
    });
};

export const getIsMatchingBroker = ({ selectedBrokerFilter, brokers }) => {
    if (!selectedBrokerFilter || !brokers.length) {
        return false;
    }

    // 36 length of uuid since selectedBrokerFilter also could be country
    const isMatchingBroker =
        selectedBrokerFilter.length === UUID_LENGTH && brokers.some(broker => broker.value === selectedBrokerFilter);

    return isMatchingBroker;
};
