import React from 'react';
import PropTypes from 'prop-types';
import { keyBy } from 'lodash-es';
import { useToggle } from '@beewise/react-utils';
import Button, { VARIANTS, SIZES, COLORS } from '@beewise/button-v2';
import { useWatch } from 'react-hook-form';
import { SelectFormField } from '@beewise/hook-form';
import { useSelector } from 'react-redux';
import { getInstallers } from 'components/views/BrokerView/selectors';
import OutsideClickHandler from 'components/reusables/OutsideClickHandler';
import SectionHeader from '../SectionHeader';
import EditableListItem from '../components/EditableListItem';
import { groupCompaniesByBrokers } from '../../../../utils';
import './Installers.scss';

const getInstallerField = ({ selectedInstallers, isEditing, handleEnableIsEditing, installerOptions, control }) => {
    if (isEditing) {
        return <SelectFormField control={control} name="installers" options={installerOptions} isMulti />;
    }
    if (selectedInstallers.length) {
        return <EditableListItem handleEdit={handleEnableIsEditing}>{selectedInstallers.join(', ')}</EditableListItem>;
    }
    return (
        <Button variant={VARIANTS.TEXT} size={SIZES.SMALL} color={COLORS.GRAY} onClick={handleEnableIsEditing}>
            + Add installer
        </Button>
    );
};

const Installers = ({ control }) => {
    const [isEditing, , handleEnableIsEditing, handleDisableIsEditing] = useToggle(false);

    const installers = useSelector(getInstallers);
    const installerOptions = groupCompaniesByBrokers(installers);
    const installerByIds = keyBy(installers, 'id');
    const selectedInstallers = useWatch({ control, name: 'installers' }).map(id => installerByIds[id]?.name);

    return (
        <OutsideClickHandler onOutsideClick={handleDisableIsEditing} className="installers">
            <SectionHeader title="Installers" icon="unpacked-box" />
            {getInstallerField({ selectedInstallers, isEditing, handleEnableIsEditing, installerOptions, control })}
        </OutsideClickHandler>
    );
};

Installers.propTypes = {
    control: PropTypes.shape().isRequired,
};

export default Installers;
