import React from 'react';
import Icon from '@beewise/icon';
import PropTypes from 'prop-types';
import './SectionHeader.scss';

const SectionHeader = ({ title, icon }) => (
    <div className="section-header">
        <Icon type={icon} />
        <h3>{title}</h3>
    </div>
);

SectionHeader.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
};

export default SectionHeader;
