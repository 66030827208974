import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { VARIANTS } from './utils';
import './Card.scss';

const Card = ({ children, className, variant }) => (
    <section className={cn('card v2', className, `card-${variant}`)}>{children}</section>
);

Card.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    variant: PropTypes.oneOf(Object.values(VARIANTS)),
};

export default Card;
export { VARIANTS };
