import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchCompanyUsers, fetchBrokers, fetchRanchManagers } from '../../actions';
import { ContactsGrid } from './components/ContactsGrid';

const ContactsView = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCompanyUsers());
        dispatch(fetchRanchManagers());
        dispatch(fetchBrokers());
    }, [dispatch]);

    return <ContactsGrid />;
};

export default ContactsView;
