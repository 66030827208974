import React, { useEffect, useRef } from 'react';
import cx from 'classnames';
import { useTransitionState } from 'react-transition-state';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import './AsidePanel.scss';

const AsidePanel = ({
    children,
    isPanelOpen,
    side,
    onClose,
    shouldCloseOnOutsideClick,
    className,
    isWithOverlay,
    isFullScreen,
}) => {
    const dispatch = useDispatch();

    const panelRef = useRef();
    const [{ isMounted }, toggle] = useTransitionState({
        mountOnEnter: true,
        unmountOnExit: true,
        preEnter: true,
        timeout: 300,
    });

    useEffect(() => {
        toggle(!!isPanelOpen);
    }, [isPanelOpen, toggle]);

    useEffect(() => {
        if (!shouldCloseOnOutsideClick || !isPanelOpen) {
            return;
        }

        const handleClose = e => {
            if (!panelRef.current?.contains(e.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClose);

        return () => {
            document.removeEventListener('mousedown', handleClose);
        };
    }, [dispatch, isPanelOpen, onClose, shouldCloseOnOutsideClick]);

    return (
        <>
            {isWithOverlay && (
                <div
                    className={cx('overlay', {
                        open: isPanelOpen,
                    })}
                    role="presentation"
                />
            )}
            <aside
                className={cx(
                    'aside-panel',
                    className,
                    { closed: !isPanelOpen, 'full-screen': isFullScreen },
                    { [side]: !!side }
                )}
                ref={panelRef}
            >
                {isMounted && children}
            </aside>
        </>
    );
};

AsidePanel.propTypes = {
    children: PropTypes.node,
    isPanelOpen: PropTypes.bool.isRequired,
    side: PropTypes.string,
    onClose: PropTypes.func,
    shouldCloseOnOutsideClick: PropTypes.bool,
    className: PropTypes.string,
    isFullScreen: PropTypes.bool,
    isWithOverlay: PropTypes.bool,
};

export default AsidePanel;
