import React from 'react';
import './MapInfo.scss';

const MapInfo = () => (
    <div className="map-info">
        <div className="map-info-block">
            <span className="map-info-block-span gray" /> Unplaced
        </div>
        <div className="map-info-block">
            <span className="map-info-block-span orange" /> Partially placed
        </div>
        <div className="map-info-block">
            <span className="map-info-block-span green" /> Fully placed
        </div>
    </div>
);

export default MapInfo;
