import React, { createContext, useCallback, useContext, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { smoothScroll } from '../utils';

export const RanchesListContext = createContext(null);

export const RanchesListProvider = ({ children }) => {
    const listRef = useRef(null);

    const scrollToRanch = useCallback(({ scrollToIndex }) => {
        if (!listRef.current) return;

        if (Number.isNaN(scrollToIndex) || scrollToIndex < 0) {
            return;
        }

        const { itemSize } = listRef.current.props;
        const itemOffset = scrollToIndex * itemSize;
        const targetOffset = itemOffset - listRef.current.props.height / 2 + itemSize / 2;
        const currentOffset = listRef.current.state.scrollOffset;

        smoothScroll(currentOffset, targetOffset, 500, listRef);
        listRef.current?.resetAfterIndex?.(0);
    }, []);

    const values = useMemo(() => ({ listRef, scrollToRanch }), [listRef, scrollToRanch]);

    return <RanchesListContext.Provider value={values}>{children}</RanchesListContext.Provider>;
};

RanchesListProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export const useRanchesList = () => {
    const context = useContext(RanchesListContext);
    return context;
};
