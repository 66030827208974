import { createSelector } from 'reselect';
import constants from 'appConstants';

export const getRanches = state => state.brokerView.ranches;
export const getBrokers = state => state.brokerView.brokers;
export const getCompanies = state => state.brokerView.companies;
export const getSelectedRanch = state => state.brokerView.selectedRanch;
export const getCurrentRanch = state => state.brokerView.selectedRanch;
export const getRanchManagers = state => state.brokerView.ranchManagers;
export const getSelectedRanchId = state => state.brokerView.selectedRanch?.id;
export const getBhomes = state => state.brokerView.bhomes;
export const getSelectedBrokerFilter = state => state.brokerView.selectedBrokerFilter;

export const getGrowers = createSelector(getCompanies, companies =>
    companies.filter(company => company.type.includes(constants.COMPANY_TYPES.GROWER))
);
export const getBeekeepers = createSelector(
    [getCompanies, (state, selectedBrokerFilter) => selectedBrokerFilter],
    (companies, selectedBrokerFilter) =>
        companies
            .filter(company => company.type.includes(constants.COMPANY_TYPES.BEEKEEPER))
            .filter(company =>
                selectedBrokerFilter
                    ? company.brokers.some(broker => broker.id === selectedBrokerFilter) ||
                      company.country === selectedBrokerFilter
                    : true
            )
);
export const getInstallers = createSelector(getCompanies, companies =>
    companies.filter(company => company.type.includes(constants.COMPANY_TYPES.INSTALLER))
);

export const getCompanyById = createSelector(
    getCompanies,
    (_, companyId) => companyId,
    (companies, companyId) => companyId && companies.find(company => company.id === companyId)
);

export const ranchManagerById = createSelector(
    getRanchManagers,
    (_, managerId) => managerId,
    (ranchManagers, managerId) => managerId && ranchManagers.find(manager => manager.id === managerId)
);
