import React, { memo, forwardRef } from 'react';
import Icon from '@beewise/icon';
import cn from 'classnames';
import PropTypes from 'prop-types';
import constants from 'appConstants';
import { getMarkerType } from './utils';
import { getBeekeeperStyles, getColorStyles } from '../utils';
import './LocationMarker.scss';

const { MARKER_HIVE_TYPES, DEFAULT_BHOME_HIVES_AMOUNT } = constants;

const ICONS = {
    [MARKER_HIVE_TYPES.BHOME]: 'beehome-inverted',
    [MARKER_HIVE_TYPES.STANDARD_HIVES]: 'wooden-hive',
};

const getLocationData = ({ placedHivesAmount, plannedHivesAmount }) => {
    if (placedHivesAmount === plannedHivesAmount) {
        return placedHivesAmount;
    } else if (!placedHivesAmount) {
        return plannedHivesAmount;
    }

    return `${placedHivesAmount} / ${plannedHivesAmount}`;
};

const SingleIcon = ({ markerType }) => <Icon type={ICONS[markerType]} color="white" />;

const MultiIcon = () => (
    <>
        <Icon type={ICONS[MARKER_HIVE_TYPES.BHOME]} color="white" />
        {' | '}
        <Icon type={ICONS[MARKER_HIVE_TYPES.STANDARD_HIVES]} color="white" />
    </>
);

const LocationMarker = forwardRef(({ onClick, marker }) => {
    const { bhomes, standardHives, hivesAmount, plannedBhomesAmount, placedHivesAmount, beekeeperColor } = marker;

    const calculatedPlacedHivesAmount =
        standardHives.reduce((sum, hive) => sum + Number(hive.hivesAmount ?? 0), 0) +
        bhomes.length * DEFAULT_BHOME_HIVES_AMOUNT;
    const markerType = getMarkerType({
        bhomes,
        standardHives,
        plannedBhomesAmount,
        hivesAmount,
    });

    const Component = markerType === MARKER_HIVE_TYPES.COMBINED ? MultiIcon : SingleIcon;
    const className = markerType === MARKER_HIVE_TYPES.COMBINED ? 'multi' : 'single';

    return (
        <div
            // we use onMouseUp instead of onClick to avoid colision with drag events
            onMouseUp={onClick}
            className={cn(
                'locations-marker',
                className,
                getColorStyles({
                    placedHivesAmount: calculatedPlacedHivesAmount,
                    plannedHivesAmount: hivesAmount,
                })
            )}
            role="presentation"
        >
            <div className="locations-marker-data">
                {getLocationData({ placedHivesAmount, plannedHivesAmount: hivesAmount })}
            </div>
            <Component markerType={markerType} />
            {beekeeperColor && <div style={getBeekeeperStyles({ color: beekeeperColor })} />}
        </div>
    );
});

LocationMarker.propTypes = {
    onClick: PropTypes.func,
    marker: PropTypes.shape({
        bhomes: PropTypes.arrayOf(PropTypes.number),
        standardHives: PropTypes.arrayOf(PropTypes.shape()),
        plannedBhomes: PropTypes.arrayOf(PropTypes.shape()),
        plannedStandardHives: PropTypes.arrayOf(PropTypes.shape()),
        placedHivesAmount: PropTypes.number,
        hivesAmount: PropTypes.number,
        plannedBhomesAmount: PropTypes.number,
        beekeeperColor: PropTypes.string,
    }),
};

SingleIcon.propTypes = {
    markerType: PropTypes.string,
};

export default memo(LocationMarker);
