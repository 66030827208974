import { FETCH_COMPANY_USERS } from '../../../actionTypes';

const contacts = (
    state = {
        companyUsers: [],
    },
    action
) => {
    switch (action.type) {
        case FETCH_COMPANY_USERS.success:
            return {
                ...state,
                companyUsers: action.data,
            };

        default:
            return state;
    }
};

export default contacts;
