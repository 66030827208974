import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import constants from 'appConstants';
import cx from 'classnames';

import './SearchBox.scss';

const latLngValidation = (lat, lng) => {
    const isLatValid = !Number.isNaN(lat) && lat >= -90 && lat <= 90;
    const isLngValid = !Number.isNaN(lng) && lng >= -180 && lng <= 180;
    return isLatValid && isLngValid;
};

const SearchBox = ({ placeholder = 'Search', customIcon, map, isCustomIconShown = true }) => {
    const input = useRef(null);
    const searchBox = useRef(null);
    const [isLatLng, setIsLatLng] = useState(false);

    const adjustedCustomIcon = useMemo(
        () =>
            customIcon &&
            React.cloneElement(customIcon, {
                className: 'custom-icon',
                onClick: () => {
                    const [lat, lng] = input.current?.value?.split(',') || [];
                    customIcon.props?.onClick({ latLng: { lat: () => +lat, lng: () => +lng } });
                },
            }),
        [customIcon]
    );

    const handleOnPlacesChanged = useCallback(() => {
        const places = searchBox.current.getPlaces();
        if (places && places[0]?.geometry) {
            const lat = places[0].geometry.location.lat();
            const lng = places[0].geometry.location.lng();
            map?.setCenter({ lat, lng });
            map?.setZoom(constants.MAP.ZOOM_LEVEL);
        }
    }, [map]);

    const handleInputChange = useCallback(() => {
        const [latStr, lngStr] = (input.current?.value || '').split(',');
        const lat = parseFloat(latStr);
        const lng = parseFloat(lngStr);
        const isLatLngValid = latLngValidation(lat, lng);

        setIsLatLng(isLatLngValid);

        if (isLatLngValid) {
            map?.setCenter({ lat, lng });
            map?.setZoom(constants.MAP.ZOOM_LEVEL);
        }
    }, [map]);

    useEffect(() => {
        if (!searchBox.current && window.google?.maps?.places) {
            searchBox.current = new window.google.maps.places.SearchBox(input.current);
            searchBox.current.addListener('places_changed', handleOnPlacesChanged);
        }
        return () => {
            window.google?.maps?.event?.clearInstanceListeners(searchBox.current);
            searchBox.current = null;
        };
    }, [handleOnPlacesChanged]);

    useEffect(() => {
        const inputElement = input.current;
        inputElement?.addEventListener('input', handleInputChange);

        return () => inputElement?.removeEventListener('input', handleInputChange);
    }, [handleInputChange]);

    return (
        <div id="map-search" className="search-box">
            <input
                className={cx({ 'with-custom-icon': !!(isCustomIconShown && isLatLng && adjustedCustomIcon) })}
                ref={input}
                placeholder={placeholder}
                autoComplete="off"
            />
            <FontAwesomeIcon icon={faSearch} />
            {isCustomIconShown && isLatLng && adjustedCustomIcon}
        </div>
    );
};

SearchBox.propTypes = {
    placeholder: PropTypes.string,
    customIcon: PropTypes.node,
    isCustomIconShown: PropTypes.bool,
    map: PropTypes.shape({
        setCenter: PropTypes.func.isRequired,
        setZoom: PropTypes.func.isRequired,
    }).isRequired,
};

export default SearchBox;
