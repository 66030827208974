import React from 'react';
import PropTypes from 'prop-types';
import './RanchDetailsItem.scss';

const RanchDetailsItem = ({ icon, title, children }) => (
    <div className="ranch-details-container-item">
        <div className="ranch-details-container-item-header">
            <div className="ranch-details-container-item-header-title">
                {icon}
                {title}
            </div>
        </div>
        <div className="ranch-details-container-item-block">{children}</div>
    </div>
);

RanchDetailsItem.propTypes = {
    icon: PropTypes.node,
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default RanchDetailsItem;
