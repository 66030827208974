import React from 'react';
import { omit, noop } from 'lodash-es';
import constants from 'appConstants';
import MapMarker from 'components/reusables/Map/components/MapMarker';

export const formatClusters = clusters =>
    clusters.map(({ wx, wy, numPoints, points, ...rest }, idx) => ({
        lat: wy,
        lng: wx,
        numPoints,
        id: `${numPoints}_${idx}`,
        points,
        type: points[0].type,
        ...rest,
    }));

export const superClusterParams = {
    minZoom: 1,
    maxZoom: 14,
    radius: 60,
};

export const defaultBounds = {
    ne: { lat: 0, lng: 180 },
    nw: { lat: 180, lng: -180 },
    se: { lat: 0, lng: 180 },
    sw: { lat: 180, lng: -180 },
};

export const updateMapBounds = (map, points) => {
    if (!points.length || !map) {
        return;
    }

    const bounds = new window.google.maps.LatLngBounds();
    points.forEach(point => bounds.extend(new window.google.maps.LatLng(point.lat, point.lng)));
    map.fitBounds(bounds);
    // if only 1 ranch is available  - zoom in to it
    if (points.length === 1 || map.zoom > 15) {
        map.setZoom(15);
    }
};

export const zoomInToBhomes = (item, map) => {
    if (item && map) {
        if (map.getZoom() >= 15) {
            map.panTo({ lat: item.lat, lng: item.lng });
        } else {
            map.setCenter({ lat: item.lat, lng: item.lng });
            map.setZoom(15);
        }
    }
};

export const drawMarkers = (markers, selectedItem, contentData, appSettings, selectedRanchQuality, isGrower) =>
    markers.map(item => (
        <MapMarker
            testId={`${item.ranchName || item.name}_${item.id}`}
            key={item.id}
            lat={item.lat}
            lng={item.lng}
            marker={omit(item, 'orderData')}
            onClick={noop}
            selectedItem={selectedItem}
            contentData={contentData?.find(content => content.id === item?.id)}
            graphDataKey={constants.GRAPH_DATA[item.crop]?.KEY ?? 'numCells'}
            ratio={item.ratio}
            country={item.country}
            selectedRanchQuality={selectedRanchQuality}
            isGrower={isGrower}
        />
    ));
