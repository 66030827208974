import React from 'react';
import { loading } from '@beewise/react-utils';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';
import RanchItem from './RanchItem';
import { useRanchesList } from '../../../providers';
import { FETCH_RANCHES } from '../../../../../actionTypes';
import './RanchesList.scss';

const TOP_HEIGHT = 290;
const ITEM_SIZE = 84;
const DUMMY_RANCH_COUNT = 11;

const RanchListContentLoader = () => (
    <div className="ranches-list">
        <ContentLoader height="100%" width="100%" speed={1} backgroundColor="#EEEEEE" foregroundColor="#BDBDBD">
            {[...Array(DUMMY_RANCH_COUNT)].map((_, index) => {
                const uniqueKey = `ranch-loader-${index}`;
                return (
                    <React.Fragment key={uniqueKey}>
                        <rect x="36" y={index * 60 + 10} rx="3" ry="3" width="20%" height="20" />
                        <rect x="calc(100% - 36px - 60%)" y={index * 60 + 10} rx="3" ry="3" width="60%" height="20" />
                    </React.Fragment>
                );
            })}
        </ContentLoader>
    </div>
);

const RanchListComponent = ({ ranches }) => {
    const { listRef } = useRanchesList();

    return (
        <AutoSizer>
            {({ height, width }) => (
                <List
                    className="ranches-list"
                    itemCount={ranches.length}
                    itemSize={ITEM_SIZE}
                    height={height - TOP_HEIGHT}
                    width={width}
                    itemData={{ ranches }}
                    ref={listRef}
                >
                    {RanchItem}
                </List>
            )}
        </AutoSizer>
    );
};

const RanchesList = loading(FETCH_RANCHES.default, RanchListContentLoader)(RanchListComponent);

export default RanchesList;

RanchListComponent.propTypes = {
    ranches: PropTypes.arrayOf(PropTypes.shape()),
};
